<template>
    <div class="mini-item" @click.prevent="openModal">
        <div class="mini-item-info">
            <div>
                <span>{{item.name}}</span>
                <span>{{item.available_stock}}</span>
            </div>
            <div>R{{`${item.price}/${item.volume}${item.units}`}}</div>
        </div>
    </div>
</template>

<script>
import { bus } from 'vue3-eventbus'
import $ from 'jquery'
import _ from 'lodash'
import { computed } from '@vue/runtime-core'
import { SHOW_MODAL } from '../../../utils/constants'
import { errorToast } from '../../../utils/toast'
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const item = computed(() => props.item)
        const openModal = () => {
            if (item.value.available_stock <= 0) {
                errorToast('This item is depleted')
                return
            } 
            bus.emit(SHOW_MODAL, {editMode: false, ..._.cloneDeep(item.value)})
            $('#selectCartItemModal').show('modal')
        }
        return {
            item,
            openModal
        }
    }
}
</script>