<template>
    <div class="reports-container">
        <div class="custom-container">
            <Navbar />
            <BackWithArrow :title="'Reports'"/>
            <div class="report-item" v-if="viewReports">
                <ReportType />
                <ReportView  v-for="purchase in purchasesByDate" :purchase="purchase" :key="purchase.id"/>                
            </div>
            <div class="report-item" v-else>
                <ReportType />
            </div>
        </div>
    </div>

</template>

<script>
import Navbar from '../../fragments/nav/Navbar.vue'
import ReportView from '../../fragments/report/ReportView.vue'
import ReportType from '../../fragments/report/ReportType.vue'
import { computed, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { bus } from 'vue3-eventbus'
import BackWithArrow from '../../fragments/nav/BackWithArrow.vue'
export default {
	components: { Navbar, ReportView, ReportType, BackWithArrow },
    setup () {

        const store = useStore()
        const purchasesByDate = computed(() => store.getters['purchase/purchasesByDate'])
        const viewReports = ref(false)

        bus.on('report-view', action => {
            viewReports.value = action
        })

        return {
            purchasesByDate,
            viewReports
        }
    }
}
</script>

<style lang="scss" scoped>

</style>