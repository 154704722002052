<template>
    <div class="report-view">
        <div class="report-day">
            {{purchase.reportDay}}
        </div>
        <!-- <div class="date-selector" v-if="isCustom">
            <div class="heading">
                <span>From</span>
                <span>-</span>
                <span>To</span>
            </div>

            <div class="dates">
                <input type="date" v-model="form.from">
                <input type="date" :min="form.from" v-model="form.to">
            </div>
        </div> -->
        <div class="report-stats">
            <div class="price">
                R{{purchase.total}}
            </div>
        </div>
    </div>  
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
    props: {
        purchase: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const purchase = computed(() => props.purchase)
        return { 
            purchase
        }
    }
}
</script>