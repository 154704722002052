import _ from 'lodash'
export const ADD_TO_CART = ( state, item ) => {
    let data = _.cloneDeep( item )
    const { item_quantity, unbranded_container_quantity, unavailable_container_quantity, container_price, branding_price, price, total_price} = data
    let index = null
    const itemExists = state.cart.filter( ( i, ind ) => {
        if ( i.id == data.id ) {
            index = ind
        }
        return i.id == data.id
    } )
    if ( itemExists.length > 0 ) {
        let itemQuantity = state.cart[index].item_quantity + item_quantity
        let unavailableContainerQuantity = state.cart[index].container_quantity + unavailable_container_quantity
        let unbrandedContainerQuantity = state.cart[index].branded_quantity + unbranded_container_quantity

        state.cart[index].item_quantity = itemQuantity
        state.cart[index].unavailable_container_quantity = unavailableContainerQuantity
        state.cart[index].total_price = sumTotal(price, itemQuantity, container_price, unavailableContainerQuantity, unbrandedContainerQuantity, branding_price)
    } else {
        data.total_price = sumTotal( price, item_quantity, container_price, unavailable_container_quantity, unbranded_container_quantity, branding_price)

        state.cart.push(data)
    }
    
}

export const UPDATE_FROM_CART = ( state, form ) => {
    const { id, item_quantity, unavailable_container_quantity, unbranded_container_quantity } = _.cloneDeep( form )
    state.cart.forEach( (element, index) => {
        if ( element.id == id ) {
            console.log(element);
            state.cart[index].item_quantity = item_quantity
            state.cart[index].unavailable_container_quantity = unavailable_container_quantity
            state.cart[index].unbranded_container_quantity = unbranded_container_quantity
            const { container_price, price, branding_price } = state.cart[index]
            state.cart[index].total_price = sumTotal( price, item_quantity, container_price, unavailable_container_quantity, unbranded_container_quantity, branding_price )
            console.log(state.cart[index].total_price);
        }
    })
}

const sumTotal = ( price, item_quantity, container_price = 0, unavailable_container_quantity = 0, unbranded_container_quantity = 0, branding_price = 0 ) => {

    const itemSum = price * item_quantity
    let totalSum = itemSum + (unavailable_container_quantity * container_price)

    if ( item_quantity != unavailable_container_quantity ) {
      totalSum += (unbranded_container_quantity * branding_price)  
    } 

    return totalSum.toFixed(2)
}

export const GET_CART_ITEM = ( state, id ) => {
    const items = _.cloneDeep(state.cart.filter( ( item ) => item.id == id ))
    if ( items.length > 0 ) {
        state.cartItem = items[0]
    }
}

export const REMOVE_FROM_CART = ( state, id ) => {
    state.cart = state.cart.filter(item => item.id != id)
}

export const CLEAR_CART = ( state ) => {
    state.cart = []
    state.cartItem = {}
}