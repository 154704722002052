<template>
    <div class="station-container">
        <div class="custom-container">
            <div class="heading" v-if="role.admin">
                <span>Allocate Stock</span>
                <i class="fa fa-plus-square-o" aria-hidden="true" @click.prevent="openModal"></i>
            </div>
            <AllocatedItems />
        </div>    
    </div>
    <AllocateStock v-if="role.admin"/>
</template>

<script>
import AllocatedItems from './inventory/AllocatedItems.vue'
import AllocateStock from '../../modals/AllocateStock.vue'
import $ from 'jquery'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
export default {
    components: { AllocatedItems, AllocateStock},
    setup () {
        
        const store = useStore()
        const role = computed(() => store.getters['user/role'])
        const openModal = () => {
            $('#allocateStockModal').show('modal')
        }
        return {
            openModal,
            role
        }
    }
}
</script>

<style lang="scss" scoped>

</style>