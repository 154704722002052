<template>
    <div id="refillItemModal" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="refillItemModalLabel">Refills</h5>
                    <button type="button" class="btn-close" @click.prevent="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <input type="number" min="0" v-model="form.amount" placeholder="Refill amount">
                        {{item && item.measurement}}
                    </div>
                    <div>
                        <select v-model="form.type">
                            <option value="">- Refill type -</option>
                            <option :value="REFILL_TYPE.add">Add</option>
                            <option :value="REFILL_TYPE.subtract">Subtract</option>
                        </select>
                    </div>
                    <div>
                        <button @click.prevent="refillItem"> Save </button>
                    </div>
                </div>
            </div>           
        </div>

    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { inject, watch } from '@vue/runtime-core'
import $ from 'jquery'
import {bus} from 'vue3-eventbus'
import { REFILL_REASON, SHOW_MODAL, REFILL_TYPE } from '../../utils/constants'
import _ from 'lodash'
import { useStore } from 'vuex'
import { errorToast, successToast } from '../../utils/toast'
import router from '../../router'
export default {
    setup () {
        const form = reactive({
            id: '',
            amount: '',
            type: '',
            reason: REFILL_REASON.update_inventory
        })

        const stockRefill = ref(false)
        const store = useStore()

        const item = ref({})
        bus.on(SHOW_MODAL, (data) => {
            stockRefill.value = router.currentRoute.value.name == 'StationInventory'
            item.value = _.cloneDeep(data)
            form.id = item.value.id
            bus.off(SHOW_MODAL)
        })
        const closeModal = () => {
            $('#refillItemModal').hide('modal')
        }

        watch(form, () => {
            if (form.amount < 0) {
                form.amount = 0
            }
        })

        const refillItem = async () => {
            if (!form.amount || form.amount == 0) {
                errorToast('Refill amount is required')
                return
            }

            if (!form.type) {
                errorToast('Refill type is required')
                return
            }

            let data = _.cloneDeep(form)
            data = { ...data, expected_revenue: (item.value.price * (form.type == REFILL_TYPE.add ? form.amount: -1 * form.amount)).toFixed(2)}
            if (stockRefill.value) {
                const { station_id, item_id } = item.value

                data = {...data,  station_id, item_id}
            }
            const { success, message } = await store.dispatch( stockRefill.value ? 'stock/refillStock' :'item/refillItem', data)

            if (!success) {
                errorToast(message)
                return
            }

            form.amount = ''
            form.type = ''
            successToast(message)
        }
        return {
            form, 
            closeModal, 
            item,
            refillItem,
            REFILL_TYPE
        }
    }
}
</script>
