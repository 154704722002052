import Purchase from '../../services/Purchase'
import { PURCHASES_REF } from '../../utils/constants'
import { statusHolder } from '../../utils/helper'
const purchase = new Purchase()

export const addPurchase = async ( _, data) => {
    return await purchase.recordPurchase(data)
}

export const getPurchasesByCashier = async ( { commit }, user_id ) => {
    try {
        PURCHASES_REF.where( 'cashier', '==', user_id ).onSnapshot( snapshot => {
            commit('GET_PURCHASES', snapshot.docs)
        }, error => {
            throw error
        } )
        
        return statusHolder()
    } catch ( error ) {
        return statusHolder(false, error.message)
    }
}

export const getPurchasesByStation = async ( { commit }, station_id ) => {
    try {
        PURCHASES_REF.where( 'station_id', '==', station_id ).onSnapshot( snapshot => {
            commit('GET_PURCHASES', snapshot.docs)
        }, error => {
            throw error
        } )
        
        return statusHolder()
    } catch ( error ) {
        return statusHolder(false, error.message)
    }
}