<template>
    <div id="categoryActionsModal" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="categoryActionsModalLabel">Category actions</h5>
                    <button type="button" class="btn-close" @click.prevent="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <button @click.prevent="updateCategory"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Update</button>
                    </div>
                    <div>
                        <button @click.prevent="deleteCategory"><i class="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                    </div>
                </div>
            </div>           
        </div>

    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { inject, watch } from '@vue/runtime-core'
import $ from 'jquery'
import {bus} from 'vue3-eventbus'
import { SHOW_MODAL } from '../../utils/constants'
import _ from 'lodash'
import { useStore } from 'vuex'
import { errorToast, successToast } from '../../utils/toast'
export default {
    setup () {
        const category = ref({})

        const store = useStore()

        bus.on(SHOW_MODAL, (data) => {
            category.value = _.cloneDeep(data)
        })

        const deleteCategory = async () => {
            const id = category.value.id
            const {success, message} = await store.dispatch('category/deleteCategory', id)
            closeModal()
            success ? successToast(message) : errorToast(message)
        }

        const updateCategory = () => {
            bus.emit(SHOW_MODAL, ({editMode: true, ...category.value}))
            closeModal()
            $('#addCategoryModal').show('modal')
        }

        const closeModal = () => {
            $('#categoryActionsModal').hide('modal')
        }

        return {
            closeModal,
            deleteCategory,
            updateCategory
        }
    }
}
</script>
