<template>
    <div class="custom-list-container">
        <div class="custom-list" v-if="stocks.length > 0">
            <Item v-for="stock in stocks" :stock="stock" :key="stock.id"/>
        </div>
        <div class="custom-list" v-else>
            Nothing here
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Item from './Item.vue'
export default {
    components: {Item},
    setup () {
        const store = useStore()
        const stocks = computed(() => store.getters['stock/stocks'])
        return {
            stocks
        }
    }
}
</script>

<style lang="scss" scoped>

</style>