import Events from '../../services/Events'
import Stock from '../../services/Stock'
import { STOCKS_REF } from '../../utils/constants'
import { statusHolder } from '../../utils/helper'
const stock = new Stock()
const events = new Events()

export const allocateStock = async ( _, form ) => {
    return await stock.allocateStock(form)    
}

export const refillStock = async ( _, form ) => {
    return stock.refillStock(form)
}

export const getStocks = ( { commit }, stationId ) => {
    try {
        events.setLoading()
        STOCKS_REF.where('station_id', '==', stationId).onSnapshot( snapshot => {
            commit( 'GET_STOCKS', snapshot.docs )
            events.resetLoading()
        }, error => {
            throw error
        })
        return statusHolder()
    } catch ( err ) {
        events.resetLoading()
        return statusHolder(false, err.message)
    }
}
export const getStock = ( { commit }, id ) => {
    try {
        events.setLoading()
        STOCKS_REF.doc(id).onSnapshot( snapshot => {
            commit( 'GET_STOCK', snapshot)
            events.resetLoading()
        }, error => {
            throw error
        })
        return statusHolder()
    } catch ( err ) {
        events.resetLoading()
        return statusHolder(false, err.message)
    }
}

export const removeStock = () => {
    
}

export const updateAvailableStock = ( { commit }, form ) => {
    commit('UPDATE_AVAILABLE_STOCK', form)
}