<template>
  <router-view />
  <Loader />
  <!-- <footer> Made with &nbsp; <i class="fa fa-heart"></i> &nbsp; by Somecode &nbsp;</footer> -->
</template>

<script>
import { provide } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Loader from './components/modals/Loader.vue'
import { auth, messaging } from './config/firebase'

export default {
  components: {Loader},
  setup() {
    const store = useStore()
    
    const notificationPermission = () => {
      Notification.requestPermission().then((pemission) => {
        if(pemission != 'granted') {
          console.log('Unable to receive messages');
        }
      })
    }
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        notificationPermission()
        await store.dispatch('user/getUserRole', user.uid)
      } else {
        
      }
    })

    if (auth.currentUser != null) {
      messaging.getToken({ vapidKey: process.env.VUE_APP_VAPID_KEY}).then((currentToken) => {
        if (currentToken) {
          store.dispatch('user/storeToken', currentToken)
        } else {
            notificationPermission()
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      })
    }


            
    messaging.onMessage(function (payload) {
      let enableForegroundNotification = true;
        if (enableForegroundNotification) {
            let notification = payload.notification;
          navigator.serviceWorker
                .getRegistrations()
                .then(async (registration) => {
                    registration[0].showNotification(notification.title);
                }).catch(e => {
                  console.log(e);
                });
        }

    });

  }
}
</script>
