import _ from 'lodash'
import moment from 'moment'
import { REPORT_DAY } from '../../utils/constants'

export const purchasesByDate = ( state ) => {
    const purchases = state.purchases
    let sumToday = 0
    let sumThisWeek = 0
    let sumLastWeek = 0
    if ( purchases.length > 0 ) {
        for ( let index = 0; index < purchases.length; index++ ) {
            let { created_at, grand_total } = purchases[index]
            grand_total = parseFloat( grand_total )
            const dateSaved = _.cloneDeep( created_at ).toDate()
            const currentDate = new Date()

            const dateDiff = moment(currentDate).diff(dateSaved, 'days')

            if ( dateDiff == 0 ) {
                sumToday += grand_total
            }
            
            if ( dateDiff >= 0 && dateDiff <= 7 ) {
                sumThisWeek += grand_total
            }
            
            if ( dateDiff > 7 && dateDiff <= 14 ) {
                sumLastWeek += grand_total
            }
        }
    }

    const sum = [
        {
            reportDay: REPORT_DAY.today,
            total: sumToday.toFixed(2)
        },
        {
            reportDay: REPORT_DAY.thisWeek,
            total: sumThisWeek.toFixed(2)
        },
        {
            reportDay: REPORT_DAY.lastWeek,
            total: sumLastWeek.toFixed(2)
        }   
    ]

    return sum
}

