<template>
    <div class="custom-item">
        <div class="item-info">
            <div>
                <div class="name">
                    Paraffin 5l
                </div>

                <div class="name">
                    Buy 10
                </div>
                <div class="name">
                    Get 1 free
                </div>
            </div>
            <div class="action-buttons">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>