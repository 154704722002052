import { bus } from 'vue3-eventbus'
import { LOADING } from '../utils/constants'
class Events {

    setLoading = () => {
        bus.emit(LOADING, true)
    }

    resetLoading = () => {
        bus.emit(LOADING, false)
    }

}

export default Events