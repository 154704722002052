<template>
    <div class="seskhona-container">
        <Navbar />
        <div class="inventory">
            <Categories />
            <Items />
        </div>
    </div>
    <AddCategory />
    <AddItem />
</template>

<script>
import Categories from '../fragments/category/Categories.vue'
import Items from '../fragments/item/Items.vue'
import Navbar from '../fragments/nav/Navbar.vue'
import AddCategory from '../modals/AddCategory.vue'

export default {
    components: { Navbar, Categories, Items, AddCategory },
    setup () {
        return {}
    }
}
</script>