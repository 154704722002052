import { createApp } from 'vue'
import App from './App.vue'
import { auth, messaging } from './config/firebase'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'cors'
import 'jquery'
import '@popperjs/core'
import 'bootstrap'
import './assets/scss/main.scss'
import eventBus from 'vue3-eventbus'
import Vue3TouchEvents from "vue3-touch-events"
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'


const options = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
}

let app

auth.onAuthStateChanged( user => {
    if ( !app ) {
        app = createApp( App ).use( store ).use( router ).use( Toast, options ).use( eventBus ).use( Vue3TouchEvents )
        app.config.globalProperties.$messaging = messaging
        app.mount( '#app' )
    }
})
