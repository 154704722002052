<template>
    <div class="navbar-container">
        <div class="nav-items">
            <div class="list-items">
                <div class="list-item" v-for="(navigator, index) in navigations" :key="index" @click.prevent="toRoute(navigator.path)">
                    <i class="fa" :class="navigator.icon"></i><span>{{navigator.title}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import router from '@/router'
import navigationData from '@/data/navigation.json'
import User from '../../../services/User'
import Station from '../../../services/Station'
export default {
    setup () {
        const navigations = ref([])

        const toRoute = (path) => {
            router.push(`/${path}`)
        }

        const setNavigation = async () => {
            const user = new User()
            const { admin, staff, op } = await (await user.userRole()).claims

            if (admin && staff) {
                navigations.value = [...navigationData.admin, ...navigationData.general]
            } else if (staff && !op) {
                navigations.value = [...navigationData.staff, ...navigationData.general]
            } else if (staff && op) {
                const station = new Station()
                const stationByUser = await station.getStationOfCurrentUser()
                navigationData.op = navigationData.op.map(nav => {
                    let data = nav
                    if (data.name == 'Station') {
                        data.path = `station/${stationByUser[0].id}/details`
                    }
                    return data
                })
                navigations.value = [...navigationData.op, ...navigationData.general]
            }

            
        }

        setNavigation()
        return {
            navigations,
            toRoute,
        }
    }
}
</script>