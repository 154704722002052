import { firestore, firebase} from '../config/firebase'
import { ADD, ADD_TO_ARRAY, COMPUTE_FIELD, ITEMS, REFILL_REASON, REFILL_TYPE, STATION_ALLOCATION } from '../utils/constants'
import { statusHolder } from '../utils/helper'
import Events from './Events'
import { auth } from '../config/firebase'
class Item {
    constructor() {
        this.itemsRef = firestore.collection( ITEMS )
        this.events = new Events()
    }

    addItem = async ( form ) => {
        try {
            this.events.setLoading()
            await this.itemsRef.add({...form, created_at: firebase.firestore.Timestamp.now()})
            this.events.resetLoading()
            return statusHolder(true, 'Item added succesfully')
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }

    editItem = async ( form ) => {
        try {
            const { id, ...other } = form
            if ( other.hasOwnProperty( 'available_stock' ) ) {
                delete other.available_stock
            }
            this.events.setLoading()
            await this.itemsRef.doc( id ).update( { other , updated_at: firebase.firestore.Timestamp.now()} )
            this.events.resetLoading()
            return statusHolder( true, 'Item edited successfully' )
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }

    getItems = async () => {
        this.events.setLoading()
        return statusHolder(true, {ref: this.itemsRef, ev: this.events})
    }

    getItem = async (id) => {
        return (await this.itemsRef.doc(id).get()).data()
    }

    getItemsByCategory = async ( id ) => {
        return (await this.itemsRef.where('category', '==', id).get()).docs
    }

    refillItem = async ( form ) => {
        try {
            const {id, amount, type, reason } = form
            const item = await this.getItem( id )
            const { uid } = auth.currentUser
            var refillObj = {
                amount,
                type,
                reason,
                created_at: new Date(),
                refilled_by: uid,
                expected_revenue: form.expected_revenue,
                total_revenue_made: reason == REFILL_REASON.station_allocation ? form.expected_revenue : 0
            }

            console.log(refillObj);
            if ( reason == REFILL_REASON.station_allocation ) {
                refillObj = {...refillObj, station_id: form.station_id}
            }

            let data = {available_stock: COMPUTE_FIELD(type == REFILL_TYPE.add ? amount : ( item.available_stock <= 0 ? 0 :amount * -1))}

            data = {
                ...data,
                refills: item.hasOwnProperty( 'refills' ) ? ADD_TO_ARRAY( refillObj ) : [refillObj],
                total_expected_revenue: COMPUTE_FIELD( reason == REFILL_REASON.station_allocation ? 0 : form.expected_revenue ),
                total_revenue_made: COMPUTE_FIELD( reason == REFILL_REASON.station_allocation ? form.expected_revenue : 0)
            }
            this.events.setLoading()
            await this.itemsRef.doc( id ).update({...data, updated_at: firebase.firestore.Timestamp.now()})
            this.events.resetLoading()
            return statusHolder(true, `${item.name} refilled successfully`)
        } catch ( err ) {
            console.log(err);
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }

        
    }

    deleteItem = async ( id ) => {
        try {
            this.events.setLoading()
            await this.itemsRef.doc( id ).delete()
            this.events.resetLoading()
            return statusHolder(true, 'Item deleted!!')
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }
}

export default Item