export const addPromotion = ( _, form ) => {
    
}

export const updatePromotion = ( _, form ) => {
    
}

export const getPromotions = ( { commit } ) => {
    
}

export const deletePromotion = ( _, id ) => {
    
}

