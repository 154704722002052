<template>
    <div class="station-container" v-if="station">
        <div class="details">
            <div class="title">
                Status
            </div>
            <div class="form">
                <div class="inputs">
                    <label class="container-checkbox">{{station.status}}
                        <input type="radio" name="radio" checked="checked">
                        <span class="checkmark"></span>
                    </label>
                </div>            
            </div>            
        </div>

        <div class="details">
            <div class="title">
                Operator
            </div>
            <div class="label">
                {{station.operator_name}}
            </div>            
        </div>

        <div class="details">
            <div class="title">
                Location
            </div>
            <div class="label">
                {{station.location}}
            </div>            
        </div>
        <div class="details">
            <div class="title">
                Created at
            </div>
            <div class="label">
                {{dateFormat(station.created_at)}}
            </div>            
        </div>
        <div class="details">
            <div class="title">
                Last Active
            </div>
            <div class="label">
                Never
            </div>            
        </div>
        <div class="details" v-if="role.admin">
            <div class="title">Actions</div>
            <router-link class="label-with-background" :to="`/edit-station/${station.id}`">
                <i class="fas fa-pen-square"></i> Edit
            </router-link>
            
        </div>
    </div>
</template>

<script>

import { dateFormat } from '../../../utils/helper'
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { STATION_STATUS } from '../../../utils/constants'
export default {
    setup () {

        const store = useStore()
        const station = computed(() => store.getters['station/station'])
        const role = computed(() => store.getters['user/role'])
        
        return {
            STATION_STATUS,
            station,
            dateFormat,
            role
        }
    }
}
</script>

<style lang="scss" scoped>

</style>