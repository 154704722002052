import { createRouter, createWebHistory } from 'vue-router'
import PageNotFound from '../components/views/404.vue'
import Home from '../components/views/Home.vue'
import Inventory from '../components/views/Inventory.vue'
import CashRegister from '../components/views/cash-register/CashRegister.vue'
import Login from '../components/views/user/Login.vue'
import Staff from '../components/views/user/Staff.vue'
import Profile from '../components/views/user/Profile.vue'
import AddItem from '../components/views/item/AddItem.vue'
import AddStaff from '../components/views/user/AddStaff.vue'
import Stations from '../components/views/station/Stations.vue'
import AddStation from '../components/views/station/AddStation.vue'
import Station from '../components/views/station/Station.vue'
import StationDetails from '../components/fragments/station/Details.vue'
import StationInventory from '../components/fragments/station/Inventory.vue'
import StationPerfomance from '../components/fragments/station/Perfomance.vue'
import Notification from '../components/views/notification/Notifications.vue'
import Reports from '../components/views/report/Reports.vue'
import Promotion from '../components/views/promotion/Promotion.vue'

import { auth } from '../config/firebase'
import { errorToast } from '../utils/toast'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/home',
    component: Home,
    name: 'Home',
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/staff',
    name: 'Staff',
    component: Staff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-item',
    name: 'AddItem',
    component: AddItem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit-item/:id',
    name: 'EditItem',
    component: AddItem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-staff',
    name: 'AddStaff',
    component: AddStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit-staff/:id',
    name: 'EditStaff',
    component: AddStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stations',
    name: 'Stations',
    component: Stations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-station',
    name: 'AddStation',
    component: AddStation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit-station/:id',
    name: 'EditStation',
    component: AddStation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/station/:id',
    name: 'Station',
    component: Station,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'details',
        name: 'StationDetails',
        component: StationDetails,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'inventory',
        name: 'StationInventory',
        component: StationInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'perfomance',
        name: 'StationPerfomance',
        component: StationPerfomance,
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path: '/cash-register',
    name: 'CashRegister',
    component: CashRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: Notification,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/promotion',
    name: 'Promotion',
    component: Promotion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
} )

router.beforeEach( async ( to, from, next ) => {
  
  const requiresAuth = to.matched.some( value => value.meta.requiresAuth )
  const user = auth.currentUser
  if ( requiresAuth && user ) {
    const { claims } = await user.getIdTokenResult()
    if ( claims.hasOwnProperty( 'staff' ) && claims.staff ) {
      next()  
    } else {
      errorToast( 'Unauthorised' )
      await auth.signOut()
      next('/')
    }
    
  } else if(!requiresAuth && user) {
    next('/home')
  } else if (requiresAuth && !user) {
    next('/')
  } else {
    next()
  }
})

export default router
