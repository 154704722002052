import User from '../../services/User'

export const GET_NOTIFICATIONS = async ( state, notifications ) => {
    const notificationArray = []
    const notiArray = notifications.map( noti => {
        return {
            id: noti.id,
            ...noti.data()
        }
    })

    for (let index = 0; index < notiArray.length; index++) {
        const element = notiArray[index];

        const user = new User()

        const { name } = await user.getUser( element.from )
        notificationArray.push({id: element.from, name})
    }

    state.notifications = notiArray.map( noti => {
        const user = notificationArray.filter( u => u.id == noti.from )
        let name = 'Not Specified'
        if ( user.length > 0 ) {
            name = user[0].name
        }
        return {
            name,
            ...noti
        }
    })
}