<template>
    <div class="custom-container">
        <Navbar />
		<div class="head">
			<div class="heading">Promo</div>
			<i class="fa" :class="showForm ? 'fa-minus-square-o': 'fa-plus-square-o'" aria-hidden="true" @click="toggleForm"></i>
		</div>
        <div class="add-promo-container" v-if="showForm">
            <AddPromotion />
        </div>
        <div class="custom-list-container" v-if="!showForm">
            <div class="custom-list">
                <PromoItem />
                <PromoItem />
                <PromoItem />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Navbar from '../../fragments/nav/Navbar.vue'
import AddPromotion from '../../fragments/promotion/AddPromotion.vue'
import PromoItem from '../../fragments/promotion/PromoItem.vue'
export default {
	components: { Navbar, PromoItem, AddPromotion },
    setup () {
        const showForm = ref(false)

        const toggleForm = () => {
            showForm.value = !showForm.value
        }
        return {
            showForm,
            toggleForm
        }
    }
}
</script>

<style lang="scss" scoped>

</style>