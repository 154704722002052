<template>
    <div id="couponModal" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="couponModalLabel">Coupon</h5>
                    <button type="button" class="btn-close" @click.prevent="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <input type="text" v-model="form.code">
                    </div>
                    <div v-if="makeNewCoupon" class="inputs">
                        <input type="email" placeholder="Enter client's email....">
                    </div>
                    <div v-if="makeNewCoupon" class="inputs">
                        <input type="text" placeholder="Enter client's contact number....">
                    </div>
                    <div class="btn-space-between">
                        <button>Check coupon</button>
                        <button @click="generateCoupon">Generate coupon</button>
                        <button> Save </button>
                    </div>
                </div>
            </div>           
        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { couponGenerator } from '../../utils/helper'
export default {
    setup () {
        const form = reactive({
            id: '',
            code: 'SE-',
            email: '',
            contact_number: '',
            active: true,
            purchases_to_win: 10,
            purchases_made: 0,
            item_id: ''
        })

        const makeNewCoupon = ref(false)

        watch(form, () => {
            if (form.code.length <= 3) {
                form.code = 'SE-'
            }
        })

        const generateCoupon = () => {
            makeNewCoupon.value = true
            form.code = couponGenerator()
        }

        return {
            form,
            generateCoupon,
            makeNewCoupon
        }
    }
}
</script>

<style lang="scss" scoped>

</style>