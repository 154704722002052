import { firestore, firebase } from '../config/firebase'
// Event names
export const LOADING = 'loading'
export const SHOW_MODAL = 'showModal'
export const HIDE_MODAL = 'hideModal'
export const GET_CHANGE = 'getChange'
// Firebase

export const CATEGORIES = 'categories'
export const ITEMS = 'items'
export const USERS = 'users'
export const STATIONS = 'stations'
export const STOCKS = 'stocks'
export const PURCHASES = 'purchases'
export const NOTIFICATIONS = 'notifications'
export const PROMOTIONS = 'promotions'
export const COUPONS = 'coupons'

export const COMPUTE_FIELD = ( value ) => {
    return firebase.firestore.FieldValue.increment(value)
}

export const ADD_TO_ARRAY = ( value ) => {
    return firebase.firestore.FieldValue.arrayUnion(value)
}

//Cloud function 
export const ADD_STAFF = 'addStaff'

// Firestore Collection

export const USERS_REF = firestore.collection( USERS )
export const ITEMS_REF = firestore.collection(ITEMS)
export const CATEGORIES_REF = firestore.collection(CATEGORIES)
export const STATIONS_REF = firestore.collection(STATIONS)
export const STOCKS_REF = firestore.collection( STOCKS )
export const PURCHASES_REF = firestore.collection( PURCHASES )
export const NOTIFICATIONS_REF = firestore.collection( NOTIFICATIONS )
export const PROMOTIONS_REF = firestore.collection( PROMOTIONS )
export const COUPONS_REF = firestore.collection( COUPONS )
// export const
export const REFILL_TYPE = {
    add: 'ADD',
    subtract: 'SUBTRACT'
}

export const REFILL_REASON = {
    station_allocation: 'STATION_ALLOCATION',
    update_inventory: 'UPDATE_INVENTORY',
    sold: 'SOLD'
}

export const ROLES = {
    admin: 'admin',
    op: 'op',
    staff: 'staff'
}

export const GENDER = {
    male: 'male',
    female: 'female',
    other: 'other'
}

export const STATION_STATUS = {
    outgoing: 'outgoing',
    closed: 'closed'
}

export const PURCHASE_TYPE = {
    order: 'ORDER',
    sale: 'SALE',
    station_sale: 'STATION_SALE'
}

export const PURCHASE_STATUS = {
    received: 'RECEIVED',
    processed: 'PROCESSED',
    processing: 'PROCESSING',
    completed: 'COMPLETED',
    delivered: 'DELIVERED'
}

export const PAYMENT_METHOD = {
    card: 'CARD',
    cash: 'CASH'
}

export const NOTIFICATION_TYPE = {
    refillRequest: 'REFILL_REQUEST'
}

export const NOTIFICATION_STATUS = {
    read: 'READ',
    unread: 'UNREAD'
}

export const REPORT_TYPE = {
    station: 'STATION',
    cashier: 'CASHIER'
}

export const REPORT_DAY = {
    today: 'Today',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    custom: 'Custom'
}