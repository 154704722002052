import { ITEMS_REF, REFILL_TYPE } from '../../utils/constants'

export const GET_STOCKS = async ( state, stocks ) => {
    const items = []
    let stockItems = stocks.map( stock => {
        return {
            id: stock.id,
            ...stock.data()
        }
    })

    for (let index = 0; index < stockItems.length; index++) {
        const { item_id: id} = stockItems[index];
        const item = await ITEMS_REF.doc( id ).get()
        if ( item.exists ) {
            const { price, name, volume, units, measurement, has_container_price} = item.data()
            let data = { id, price, name, volume, units, measurement, has_container_price }
            if ( has_container_price ) {
                const { container_price } = item.data() 
                data = {...data, container_price }
            }
            items.push( data )
        }
    }
    
    state.stocks = stockItems.map( stock => {
        const item = items.filter( ( i ) => i.id === stock.item_id)
        delete item[0].id
        return {
            ...stock,
            ...item[0]
        }
    })
}

export const GET_STOCK = ( state, stock ) => {
    state.stock = {
        id: stock.id,
        ...stock.data()
    }
}

export const UPDATE_AVAILABLE_STOCK = ( state, form ) => {
    const { id, available_stock } = _.cloneDeep( form )
    state.stocks.forEach( ( stock, index ) => {
        if ( stock.id == id ) {
            state.stocks[index].available_stock = available_stock
        }
    })
}