import Station from '../../services/Station'
import Events from '../../services/Events'
import { STATIONS_REF } from '../../utils/constants'
const station = new Station()
const ev = new Events()

export const addStation = async ( _, form ) => {
    return await station.addStation(form)
}

export const updateStation = async ( _, form ) => {
    return await station.updateStation(form)
}

export const getStations = async ( { commit } ) => {
    try {
        ev.setLoading()
        STATIONS_REF.onSnapshot( snapshot => {
            commit( 'GET_STATIONS', snapshot.docs )
            ev.resetLoading()
        }, err => {
            throw err
        })
    } catch ( error ) {
        ev.resetLoading()
        console.log(error);
    } 
}
export const getStation = async ( { commit }, id ) => {
    try {
        ev.setLoading()
        STATIONS_REF.doc(id).onSnapshot( snapshot => {
            commit( 'GET_STATION', snapshot )
            ev.resetLoading()
        }, err => {
            throw err
        })
    } catch ( error ) {
        ev.resetLoading()
        console.log(error);
    } 
}