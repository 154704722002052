<template>
    <p class="heading">Categories</p>
    <div class="categories-container">
        <div class="categories" v-if="categories.length > 0">
            <div data-bs-toggle="modal" data-bs-target="#addCategoryModal">
                <i class="fa fa-plus"></i>
            </div>
            <Category v-for="category in categories" :category="category" :key="category.id"/>              
        </div>

        <div class="categories" v-else>
            <div data-bs-toggle="modal" data-bs-target="#addCategoryModal">
                <i class="fa fa-plus"></i>
            </div>
        </div>
    </div>

    <CategoryActions />
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Category from './Category'
import CategoryActions from '../../modals/CategoryActions'
export default {
    components: { Category, CategoryActions },
    setup () {
        const store = useStore()
        const categories = computed(()=> store.getters['category/categories'])
        store.dispatch('category/getCategories')
        return {
            categories
        }
    }
}
</script>

<style lang="scss" scoped>

</style>