import { auth, firestore, functions, storage, firebase } from '../config/firebase'
import { ADD_STAFF, USERS, USERS_REF } from '../utils/constants'
import { statusHolder, uploadImage } from '../utils/helper'
import Events from './Events'
class User {
    constructor() {
        this.userRef = firestore.collection(USERS)
        this.currentUser = auth.currentUser
        this.events = new Events()
    }

    login = async ({email, password}) => {
        try {
            this.events.setLoading()
            await auth.signInWithEmailAndPassword( email, password )
            this.events.resetLoading()
            return statusHolder()
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }

    register = async (form) => {
        try {
            this.events.setLoading()
            const addStaff = functions.httpsCallable( 'addStaff' )
            var { half_image, ...formData} = form
            const response = await (await addStaff( formData )).data
            const { success, message } = response
            if ( success ) {
                const { uid } = message
                
                if ( half_image ) {
                    const path = `images/staff/${ uid }`
                    half_image = await uploadImage(path, half_image)
                }

                await this.userRef.doc( uid ).set( { ...formData, half_image, created_at: new Date(), disabled: false} )
                this.events.resetLoading()
                return statusHolder(true, 'User Added')
            }
            this.events.resetLoading()
            return response
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }

    logout = async () => {
        try {
            this.events.setLoading()
            await auth.signOut()
            localStorage.removeItem('isTokenStored')
            this.events.resetLoading()
            return statusHolder()
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }

    profile = async () => {
        try {
            return await this.userRef.doc(this.currentUser.uid).get()
        } catch ( err ) {
            return statusHolder(false, err.message)
        }
    }

    userRole = async () => {
        return await auth.currentUser.getIdTokenResult() 
    }

    updateUser = async ( form ) => {
        try {
            this.events.setLoading()
            var { id, created_at, ...userData } = form

            if ( !userData.half_image.startsWith( 'https://firebasestorage.googleapis.com' ) ) {
                const path = `images/staff/${ id }`
                userData.half_image = await uploadImage(path, userData.half_image)
            }

            if ( userData.hasOwnProperty( 'roleChange' ) ) {
                const changeRole = functions.httpsCallable( 'changeUserRole' )
                await changeRole( { id, role: userData.role } )
                delete userData.roleChange
            }

            await this.userRef.doc( id ).update( { ...userData, updated_at: new Date() } )
            
            this.events.resetLoading()
            return statusHolder(true, 'User info updated')
        } catch ( error ) {
            this.events.resetLoading()
            return statusHolder(false, error.message)
        }
    }

    getUsersByRole = async (role) => {
        return (await USERS_REF.where('role', '==', role).get()).docs
    }

    getUser = async ( id ) => {
        return (await USERS_REF.doc(id).get()).data()
    }

    deleteUser = async ( id ) => {
        console.log(id);
        try {
            this.events.setLoading()
            await this.userRef.doc( id ).delete()
            this.events.resetLoading()
            return statusHolder(true, 'User deleted!!')
        } catch ( error ) {
            this.events.resetLoading()
            return statusHolder(false, error.message)
        }
    }

    storeToken = async ( token ) => {
        const isTokenStored = localStorage.getItem( 'isTokenStored' )
        console.log(isTokenStored);
        if ( isTokenStored == null ) {
            this.userRef.doc( auth.currentUser.uid ).update( { 'web_token': token, updated_at: new Date()} )
            localStorage.setItem('isTokenStored', true)
        }
    }
}

export default User