import { createStore } from 'vuex'

import user from './user'
import category from './category'
import item from './item'
import station from './station'
import stock from './stock'
import cart from './cart'
import purchase from './purchase'
import notification from './notifications'
import promotion from './promotion'
import coupon from './coupon'

export default createStore({
  modules: {
    user,
    category,
    item,
    station,
    stock,
    cart,
    purchase,
    notification,
    promotion,
    coupon
  }
})
