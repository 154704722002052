import Item from '../../services/Item'
import { ITEMS_REF } from '../../utils/constants'
const item = new Item()
export const addDeliveryDate = async ( { commit }, form ) => {
    commit('ADD_DELIVERY_DATE', form)
}
export const deleteDeliveryDate = async ({commit}, form ) => {
    commit('DELETE_DELIVERY_DATE', form)
}
export const addPurchaseTime = async ({commit}, form ) => {
    commit('ADD_PURCHASE_TIME', form)
}
export const deletePurchaseTime = async ({commit}, form ) => {
    commit('DELETE_PURCHASE_TIME', form)
}

export const addItem = async ( {commit}, form ) => {
    const res = await item.addItem( form )
    if ( res.success ) {
        commit('ADD_ITEM_DONE')
    }
    return res
}

export const editItem = async ( _ , form ) => {
    return await item.editItem(form)
}

export const getItems = async ( { commit } ) => {
    ITEMS_REF.onSnapshot( snapshot => {
        commit('GET_ITEMS', snapshot.docs)
    }, err => {
        console.log(err);
    })
}
export const getSellableItems = async ( { commit } ) => {
    ITEMS_REF.where('sellable', '==', true).onSnapshot( snapshot => {
        if ( snapshot.docChanges == 'update' ) {
            commit('GET_ITEMS', snapshot.docs)    
        }
        commit('GET_ITEMS', snapshot.docs)
    }, err => {
        console.log(err);
    })
}

export const getItem = async ( { commit }, id ) => {
    const response = await item.getItem( id )
    commit('GET_ITEM', response)
}
export const refillItem = async ( _, form ) => {
    return await item.refillItem(form)
}
export const deleteItem = async ( _, id ) => {
    return await item.deleteItem(id)
}

export const updateAvailableStock = ( { commit }, form ) => {
    commit('UPDATE_AVAILABLE_STOCK', form)
}