<template>
    <div>
        <div class="head-with-back">
            <i class="fa fa-chevron-left" aria-hidden="true" @click.prevent="$router.push('/')"></i>
            <div class="heading">
                {{title}}
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup (props) {
        const title = computed(()=> props.title)
        return {
            title
        }
    }
}
</script>

<style lang="scss" scoped>

</style>