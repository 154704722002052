import { REFILL_TYPE } from '../../utils/constants';
export const ADD_DELIVERY_DATE = (state, form) => {
    state.deliveryDates.push(form);
};

export const DELETE_DELIVERY_DATE = (state, index) => {
    state.deliveryDates.splice(index, 1);
};
export const ADD_PURCHASE_TIME = (state, form) => {
    state.purchaseTimes.push(form);
};

export const DELETE_PURCHASE_TIME = (state, index) => {
    state.purchaseTimes.splice(index, 1);
};

export const ADD_ITEM_DONE = state => {
    state.deliveryDates = [];
    state.purchaseTimes = [];
};

export const GET_ITEMS = (state, items) => {
    state.items = items.map(item => {
        let data = {
            id: item.id,
            ...item.data()
        };
        console.log(data);
        data.price = parseFloat(
            parseFloat(data.price) + parseFloat(data.lid_price)
        ).toFixed(2);
        console.log(data);
        return data;
    });
};

export const GET_ITEM = (state, item) => {
    state.item = item;
};

export const UPDATE_AVAILABLE_STOCK = (state, form) => {
    const { id, available_stock } = _.cloneDeep(form);
    state.items.forEach((item, index) => {
        if (item.id == id) {
            state.items[index].available_stock = available_stock;
        }
    });
};
