<template>
    <div class="custom-container">
        <div class="head-with-back">
            <i class="fa fa-chevron-left" aria-hidden="true" @click.prevent="$router.push(role.admin ? '/stations': '/home')"></i>
            <div class="heading">
                Station
                <div class="sub-heading" v-if="station">
                    {{station.name}}
                </div>
            </div>
        </div>
        <div class="navigation" id="nav">
            <router-link :to="`/station/${id}/details`">Details</router-link>
            <router-link :to="`/station/${id}/inventory`">Inventory</router-link>
            <router-link v-if="role.admin" :to="`/station/${id}/perfomance`">Perfomance</router-link>
        </div>
        <div class="view-area">
            <router-view />
        </div>
    </div>
    <Refills />
</template>

<script>
import _ from 'lodash'
import { reactive, ref } from '@vue/reactivity'
import router from '../../../router'
import { useStore } from 'vuex'
import { computed, watch} from '@vue/runtime-core'
import Refills from '../../modals/Refills.vue'

export default {
	components: { Refills },
    setup () {
        const store = useStore()
        const id = ref(router.currentRoute.value.params.id)
        const station = computed(() => store.getters['station/station'])
        const role = computed(() => store.getters['user/role'])

        const setupStation = async () => {
            await store.dispatch('station/getStation', id.value)
        }
        watch (station, () => {
            store.dispatch('stock/getStocks', id.value)
        })
        setupStation()
        return {
            id,
            station,
            role
        }
    }
}
</script>

<style lang="scss" scoped>

</style>