<template>
    <div class="custom-item">
        <div class="item-info">
            <div>
                <div class="name"> {{station.name}}</div>
                <div class="name"> {{station.operator_name}} </div>
                <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> <span>{{station.location}}</span></div>
            </div>
            <div class="status">{{station.status}}</div>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import _ from 'lodash'
export default {
    props: {
        station: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const store = useStore()
        const station = computed(() => props.station)

        return {
            station
        }
    }
}
</script>

<style lang="scss" scoped>

</style>