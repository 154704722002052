import { NOTIFICATIONS_REF, ROLES } from '../utils/constants'
import { statusHolder } from '../utils/helper'
import Events from './Events'
import User from './User'
import Item from './Item'
import Station from './Station'

import { firebase, functions, auth } from '../config/firebase'
class Notification {
    constructor() {
        this.events = new Events()
    }
    addNotification = async ( form ) => {
        try {
            const user = new User()
            const users = await user.getUsersByRole( ROLES.admin )
            
            if ( users.length == 0 ) {
                return statusHolder(false, 'No one is available')
            }
            const item = new Item()
            const station = new Station()

            let { name: itemName, volume, units } = await item.getItem( form.item_id )
            let { name: stationName } = await station.getStation( form.station_id )
            

            for (let index = 0; index < users.length; index++) {
                const element = users[index];
                const id = element.id
                const email = element.data().email
                let emailData = {
                    name: auth.currentUser.displayName,
                    message: `I am at <span>${ stationName }</span>, I am out of <span>${ itemName } ${ volume }${ units }</span>`,
                    subject: 'Refill request',
                    email
                }
                let data = {
                    ...form,
                    to: id,
                    created_at: firebase.default.firestore.Timestamp.now()
                }

                NOTIFICATIONS_REF.add( data )
                const sendMessage = functions.httpsCallable( 'sendMessage' )
                console.log(emailData);
                const res = await sendMessage( emailData )
                console.log(res);
            }
            return statusHolder(true, 'Sent')
        } catch ( error ) {
            return statusHolder(false, error.message)
        }
    }
}

export default Notification