<template>
    <div class="select-input">
        <select v-model="reportType">
            <option value="" disabled>- Select report type -</option>
            <option :value="REPORT_TYPE.station">Station</option>
            <option :value="REPORT_TYPE.cashier">Cashier</option>
        </select>

        <select v-model="elementId" v-if="reportType == REPORT_TYPE.station ? stations.length > 0 : users.length > 0">
            <option value="" disabled>- Select a {{reportType == REPORT_TYPE.station ? 'station': 'cashier'}} -</option>
            <option v-for="element in reportType == REPORT_TYPE.station ? stations : users" :value="element.id" :key="element.id">{{element.name}}</option>
        </select>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { REPORT_TYPE } from '../../../utils/constants'
import { computed , watch} from '@vue/runtime-core';
import { useStore } from 'vuex';
import { errorToast } from '../../../utils/toast';
import { bus } from 'vue3-eventbus'
export default {
    setup() {
        const reportType = ref(REPORT_TYPE.cashier)
        const elementId = ref('')
        const store = useStore()
        const stations = computed(() => store.getters['station/stations'])
        const users = computed(() => store.getters['user/users'])
        const viewReports = ref(false)
        const reportTypeSetup = async () => {
            switch(reportType.value) {
                case REPORT_TYPE.station:
                    if (stations.value.length == 0) {
                        await store.dispatch('station/getStations')
                    }
                    viewReports.value = false
                    break
                case REPORT_TYPE.cashier:
                    if ( users.value.length == 0) {
                        await store.dispatch('user/getUsers')
                    }
                    viewReports.value = false
                    break
            }
        }

        watch(reportType, () => {
            if (!reportType.value) {
                viewReports.value = false
            }
        })
        
        watch(elementId, async () => {
            switch(reportType.value) {
                case REPORT_TYPE.station:
                    await store.dispatch('purchase/getPurchasesByStation', elementId.value)

                    viewReports.value = true
                    break
                case REPORT_TYPE.cashier:
                    await store.dispatch('purchase/getPurchasesByCashier', elementId.value)
                    viewReports.value = true
                    break
                default:
                    viewReports.value = false
                    errorToast('Select a report type first')
                    break
            }
        })

        watch(viewReports, () => {
            bus.emit('report-view', viewReports.value)
        })
        watch(reportType, () => {
            reportTypeSetup()
        })
        reportTypeSetup()
        return {
            reportType,
            stations,
            users,
            REPORT_TYPE,
            elementId
        };
    }
};
</script>

<style lang="scss" scoped></style>
