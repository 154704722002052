import Stock from './Stock';
import Item from './Item';
import User from './User';
import Station from './Station';
import {
    PURCHASES_REF,
    PURCHASE_STATUS,
    REFILL_REASON,
    REFILL_TYPE
} from '../utils/constants';
import Events from './Events';
import { statusHolder } from '../utils/helper';
import { firebase } from '../config/firebase';
class Purchase {
    constructor() {
        this.events = new Events();
    }

    recordPurchase = async cart => {
        try {
            this.events.setLoading();
            const stock = new Stock();
            const item = new Item();
            const user = new User();
            const { staff, op } = (await user.userRole()).claims;
            let cartObj = _.cloneDeep(cart);

            if (staff && op) {
                const stationObj = new Station();
                const station = (await stationObj.getStationOfCurrentUser())[0];
                cartObj = { ...cartObj, station_id: station.id };
            }
            // const { id: purchaseId } =
            const { id } = await PURCHASES_REF.add({
                ...cartObj,
                created_at: firebase.default.firestore.Timestamp.now(),
                status: PURCHASE_STATUS.completed
            });
            // const { cart_items } = cartObj

            // for ( let index = 0; index < cart_items.length; index++ ) {
            //     const element = cart_items[index]
            //     const { item_quantity,  id } = _.cloneDeep( element )

            //     let data = {
            //         id,
            //         amount: item_quantity,
            //         type: REFILL_TYPE.subtract,
            //         reason: REFILL_REASON.sold
            //     }
            //     if ( staff && !op ) {
            //         await item.refillItem( data )
            //     } else {
            //         data = { ...data,
            //             station_id: cartObj.station_id,
            //             grand_total: cartObj.grand_total,
            //             purchase_id: purchaseId
            //         }
            //         await stock.refillItem( data )
            //     }
            // }

            // await PURCHASES_REF.doc( purchaseId ).update( { status: PURCHASE_STATUS.completed } )
            this.events.resetLoading();
            return statusHolder(true, id);
        } catch (error) {
            this.events.resetLoading();
            return statusHolder(false, error.message);
        }
    };

    reversePurchase = async form => {};
}

export default Purchase;
