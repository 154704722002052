<template>
    <div class="cash-register-container">
        <Navbar />
        <Search />
        <div class="custom-container">
            <div class="custom-mini-list-container">
                <div
                    class="custom-mini-list"
                    v-if="items.length > 0 || stocks.length > 0"
                >
                    <Item
                        v-for="item in role.staff && !role.op ? items : stocks"
                        :key="item.id"
                        :item="item"
                    />
                </div>
            </div>
        </div>
        <div class="cart" v-if="cart.length > 0">
            <div class="cart-items">
                <h5>
                    Cart <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                </h5>
                <CartItem
                    v-for="(item, index) in cart"
                    :item="item"
                    :key="index"
                />
                <div class="cart-total">
                    <span>Total</span>
                    <span>R{{ cartTotal }}</span>
                </div>
            </div>
            <div class="cart-actions">
                <button @click="voidAll">Void All</button>
                <button @click="showModal">
                    <span>Pay</span>
                    <i class="fas fa-money-bill-wave"></i>
                </button>
                <button @click="proceed">
                    <span>Pay</span>
                    <i class="fas fa-credit-card"></i>
                </button>
            </div>
        </div>
    </div>
    <SelectCartItem />
    <GetChange />
    <Coupon />
</template>

<script>
import Navbar from '../../fragments/nav/Navbar.vue';
import Item from '../../fragments/cash-register/Item.vue';
import CartItem from '../../fragments/cart/Item.vue';
import Search from '../../fragments/search/Search.vue';
import { useStore } from 'vuex';
import { computed, reactive, watch } from '@vue/runtime-core';
import SelectCartItem from '../../modals/SelectCartItem.vue';
import Print from '../../../services/Print'
const { printReceipt } = new Print()
import _ from 'lodash';
import {
    PAYMENT_METHOD,
    PURCHASE_STATUS,
    PURCHASE_TYPE,
    GET_CHANGE
} from '../../../utils/constants';
import { auth } from '../../../config/firebase';
import { errorToast, successToast } from '../../../utils/toast';
import { bus } from 'vue3-eventbus';
import $ from 'jquery';
import GetChange from '../../modals/GetChange.vue';
import Coupon from '../../modals/Coupon.vue';
import Station from '../../../services/Station';
export default {
    components: { Navbar, Item, CartItem, Search, SelectCartItem, GetChange, Coupon },
    setup() {
        const store = useStore();
        const items = computed(() => store.getters['item/items']);
        const stocks = computed(() => store.getters['stock/stocks']);
        const cart = computed(() => store.getters['cart/cart']);
        const cartTotal = computed(() => store.getters['cart/cartTotal']);
        const role = computed(() => store.getters['user/role']);
        const form = reactive({
            grand_total: cartTotal.value,
            change: 0,
            total_money: 0,
            payment_method: PAYMENT_METHOD.cash,
            cashier: auth.currentUser.uid,
            status: PURCHASE_STATUS.processing,
            client_id: '',
            type:
                role.value.staff && !role.value.op
                    ? PURCHASE_TYPE.sale
                    : PURCHASE_TYPE.station_sale,
            cart_items: []
        });
        const cashRegisterSetup = async () => {
            const { admin, staff, op } = _.cloneDeep(role.value)
            if (staff && !op) {
                await store.dispatch('item/getItems');
            } else {
                const station = new Station()

                const stationId = (await station.getStationOfCurrentUser())[0].id

                await store.dispatch('stock/getStocks', stationId);
            }
        };

        const voidAll = () => {
            const { staff, op } = role.value;
            if (cart.value.length > 0) {
                cart.value.forEach(element => {
                    let { id, item_quantity } = _.cloneDeep(element);
                    let filterItem =
                        staff && !op
                            ? items.value.filter(i => _.cloneDeep(i).id == id)
                            : stocks.value.filter(i => _.cloneDeep(i).id == id);
                    let { available_stock } = _.cloneDeep(filterItem[0]);
                    available_stock += item_quantity;
                    store.dispatch(
                        staff && !op
                            ? 'user/updateAvailableStock'
                            : 'stock/updateAvailableStock',
                        { id, available_stock }
                    );
                    store.dispatch('cart/removeFromCart', id);
                });
            }
        };

        const proceed = async () => {
            form.payment_method = PAYMENT_METHOD.card;

            await makePayment();
        };

        const couponModal = () => {
            $('#couponModal').show('modal')
        }

        const makePayment = async () => {
            form.grand_total = cartTotal.value;
            form.cart_items = cart.value.map(item => {
                const {
                    has_container_price,
                    available_stock,
                    ...other
                } = _.cloneDeep(item);
                return other;
            });
            if (form.payment_method == PAYMENT_METHOD.card) {
                form.total_money = form.grand_total;
            }
            const { success, message } = await store.dispatch(
                'purchase/addPurchase',
                form
            );

            if (!success) {
                errorToast(message);
                return;
            }
            await printReceipt({grand_total: cartTotal.value, cart: cart.value, reference: message})
            store.dispatch('cart/clearCart');
            successToast('Purchase Stored');
        };

        const showModal = () => {
            $('#getChangeModal').show('modal');
        };

        bus.on(GET_CHANGE, data => {
            const { change, cash } = _.cloneDeep(data);
            bus.off(GET_CHANGE)
            form.change = change;
            form.total_money = cash;
            form.payment_method = PAYMENT_METHOD.cash;
            makePayment();
        });
        cashRegisterSetup();
        return {
            role,
            items,
            stocks,
            cart,
            cartTotal,
            voidAll,
            proceed,
            showModal,
            couponModal,
            printReceipt
        };
    }
};
</script>
