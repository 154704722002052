import { firestore } from '../../config/firebase';
import Events from '../../services/Events';
import User from '../../services/User';
import { USERS } from '../../utils/constants';
import { statusHolder } from '../../utils/helper';
const user = new User();
const event = new Events();

export const login = async (_, form) => {
    return await user.login(form);
};

export const addUser = async (_, form) => {
    return await user.register(form);
};

export const updateUser = async (_, form) => {
    return await user.updateUser(form);
};

export const logout = async () => {
    return await user.logout();
};

export const getUsers = async ({ commit }) => {
    try {
        firestore
            .collection(USERS)
            .where('role', '!=', 'client')
            .onSnapshot(
                snapshot => {
                    commit('GET_USERS', snapshot.docs);
                },
                err => {
                    throw err;
                }
            );
        return statusHolder();
    } catch (err) {
        return statusHolder(false, err.message);
    }
};

export const getUsersByRole = async ({ commit }, role) => {
    try {
        firestore
            .collection(USERS)
            .where('role', '==', role)
            .onSnapshot(
                snapshot => {
                    commit('GET_USERS', snapshot.docs);
                },
                err => {
                    throw err;
                }
            );
        return statusHolder();
    } catch (err) {
        return statusHolder(false, err.message);
    }
};
export const getUser = async ({ commit }, id) => {
    try {
        firestore
            .collection(USERS)
            .doc(id)
            .onSnapshot(
                snapshot => {
                    commit('GET_USER', snapshot);
                },
                err => {
                    throw err;
                }
            );
        return statusHolder();
    } catch (err) {
        return statusHolder(false, err.message);
    }
};

export const getUserRole = async ({ commit }, id) => {
    const claims = (await user.userRole()).claims;
    commit('GET_USER_ROLE', claims);
};

export const deleteUser = async (_, id) => {
    return await user.deleteUser(id);
};

export const storeToken = async (_, token) => {
    await user.storeToken(token);
};
