<template>
    <div class="modal" id="getChangeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header border-0">
                <h5 class="modal-title" id="getChangeModalLabel">Pay with cash</h5>
                <button @click.prevent="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="span-total">
                    <span>Total</span>
                    <span>R{{cartTotal}}</span>
                </div>
                <div>
                    <input type="text" v-model="cash" placeholder="Enter th e amount e.g. 500">
                </div>
                <div class="span-total">
                    <span>Change</span>
                    <span>R{{change}}</span>
                </div>
                <div class="btn-space-between">
                    <button @click="proceed"> Proceed</button>
                    <button @click="closeModal">Cancel</button>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import $ from 'jquery'
import { bus } from 'vue3-eventbus'
import { errorToast } from '../../utils/toast'
import { GET_CHANGE } from '../../utils/constants'
export default {
    setup () {
        const store = useStore()
        const cartTotal = computed(() => store.getters['cart/cartTotal'])
        const change = ref(0.00)
        const cash = ref(parseFloat(0).toFixed(2))

        watch(cash, (newValue) => {
            let num = parseFloat(newValue).toFixed(2)
            cash.value =  isNaN(num) ? 0.00: num
            let grandTotal = parseFloat(cartTotal.value)
            if (cash.value >= grandTotal) {
                change.value = cash.value - grandTotal 
            } else {
                change.value = 0.00
            }
            change.value.toFixed(2)
        })

        const proceed = () => {
            if (change.value >= cash.value) {
                errorToast('You must have made an error')
                return
            }
            bus.emit(GET_CHANGE, {change: change.value, cash: cash.value})
            closeModal()
        }

        const closeModal = () => {
            change.value = 0.00
            cash.value = 0.00
            $('#getChangeModal').hide('modal')
        }
        return {
            cartTotal,
            change,
            cash,
            closeModal,
            proceed 
        }
    }
}
</script>

<style lang="scss" scoped>

</style>