
import Category from '../../services/Category'
import { CATEGORIES_REF } from '../../utils/constants'
import Events from '../../services/Events'

const category = new Category()

export const addCategory = async (_, form) => {
    return await category.addCategory(form)
}

export const editCategory = async ( _, form ) => {
    return await category.editCategory(form)
}

export const getCategories = async ( { commit } ) => {
    const { setLoading, resetLoading } = new Events()
    setLoading()
    CATEGORIES_REF.onSnapshot( snapshot => {
        resetLoading()
        commit('GET_CATEGORIES', snapshot.docs)
    }, err => {
        console.log(err);
    })
    
}

export const deleteCategory = async ( _, id ) => {
    return await category.deleteCategory(id)
}