import { auth } from '../../config/firebase'
import Notification from '../../services/Notification'
import { NOTIFICATIONS_REF, NOTIFICATION_STATUS, NOTIFICATION_TYPE } from '../../utils/constants'
import { statusHolder } from '../../utils/helper'
const notification = new Notification()

export const addNotification = async ( _, form ) => {
    return await notification.addNotification(form)
}

export const getNotifications = async ( { commit } ) => {
    try {
        NOTIFICATIONS_REF.where( 'to', '==', auth.currentUser.uid ).onSnapshot( snapshot => {
            commit('GET_NOTIFICATIONS', snapshot.docs)
        },error => {
            throw error
        } )

    } catch ( error ) {
        return statusHolder(false, error.message)
    }

}