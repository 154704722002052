<template>
    <div class="item">
        <div class="category">{{categoryName}}</div>
        <div class="item-name">{{item.name}}</div>
        <div class="price" v-if="item.sellable">{{`R${item.price}/${item.volume}${item.units}`}}</div>
        <div class="stock-available">{{`${item.available_stock} ${item.measurement} Available`}}</div>
        <div class="action-buttons">
            <button @click.prevent="refillItem">Refill</button>
            <button @click.prevent="$router.push(`/edit-item/${item.id}`)">Edit</button>
            <button @click.prevent="deleteItem">Delete</button>
        </div>
    </div>
    
</template>

<script>
import { computed, reactive, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import _ from 'lodash'
import { errorToast, successToast } from '../../../utils/toast'
import $ from 'jquery'
import { bus } from 'vue3-eventbus'
import { SHOW_MODAL } from '../../../utils/constants'
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const store = useStore()
        const categories = computed(() => store.getters['category/categories'])
        const item = computed(() => props.item)

        const categoryName = ref(null)

        watch(categories, () => {
            getCategoryName()
        })

        const getCategoryName = () => {
            if (categories.value.length == 0) {
                return
            }
            const category = _.cloneDeep(categories.value).filter(({id}) => id == item.value.category)
            categoryName.value = category[0].name
        }

        const deleteItem = async () => {
            const {success, message} = await store.dispatch('item/deleteItem', item.value.id)

            if(!success) {
                errorToast(message)
                return
            }

            successToast(message)
        }

        const refillItem = async () => {
            bus.emit(SHOW_MODAL, item.value)
            $('#refillItemModal').show('modal')
        }

        getCategoryName()
        return {
            item,
            categoryName,
            categories,
            deleteItem,
            refillItem
        }
    }
}
</script>

<style lang="scss" scoped>

</style>