<template>
    <div class="cart-item" v-touch:swipe="removeItem" @dblclick.prevent="removeItem" @click.prevent="updateItem">
        <div class="item-name-price">
            <div>{{`${item.name} ${item.volume}${item.units} @R${item.price} x ${item.item_quantity}`}}</div>
            <div>R{{item.total_price}}</div>
        </div>
        <div class="item-container" v-if="item.unavailable_container_quantity > 0">
            {{`+Container @ R${item.container_price} x ${item.unavailable_container_quantity}`}}
        </div>
        <div class="item-container" v-if="item.unbranded_container_quantity > 0">
            {{`+Unbranded container @ R${item.branding_price} x ${item.unbranded_container_quantity}`}}
        </div>
    </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { SHOW_MODAL } from '../../../utils/constants'
import _ from 'lodash'
import $ from 'jquery'
import { bus } from 'vue3-eventbus'
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const store = useStore()
        const item = computed(() => props.item)
        const cartItem = computed(() => store.getters['cart/cartItem'])
        const items = computed(() => store.getters['item/items'])
        const stocks = computed(() => store.getters['stock/stocks'])
        const role = computed(() => store.getters['user/role'])
        const click = ref(0)

        const updateItem = () => {
            click.value++

            setTimeout(() => {
                if (click.value == 1) {
                    bus.emit(SHOW_MODAL, {editMode: true, ..._.cloneDeep(item.value)})
                    $('#selectCartItemModal').show('modal')
                } else {
                    click.value = 0
                }

            }, 500);
            
        }
        const removeItem = async () => {
            store.dispatch('cart/getCartItem', item.value.id)
            const { staff, op } = role.value
            let { id, item_quantity } = _.cloneDeep(cartItem.value)
            let filterItem = staff && !op ? items.value.filter(i => _.cloneDeep(i).id == id) : stocks.value.filter(i => _.cloneDeep(i).id == id)
            let { available_stock } = _.cloneDeep(filterItem[0])
            available_stock+= item_quantity
            store.dispatch(staff && !op ? 'item/updateAvailableStock': 'stock/updateAvailableStock', {id: item.value.id, available_stock})
            store.dispatch('cart/removeFromCart', _.cloneDeep(cartItem.value).id)
        }
        return {
            item,
            removeItem,
            updateItem
        }
    }
}
</script>

<style lang="scss" scoped>

</style>