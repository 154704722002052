<template>
	<div class="custom-container">
		<Navbar />
		<div class="head">
			<div class="heading">Stations</div>
			<i class="fa fa-plus-square-o" aria-hidden="true" @click.prevent="$router.push('/add-station')"></i>
		</div>
		<div class="search-container">
			<div class="search">
				<input type="text" placeholder="search for a station here...">
				<i class="fa fa-search" aria-hidden="true"></i>	
			</div>
		</div>
		<div class="custom-list-container">
			<div class="custom-list" v-if="stations.length > 0">
                <StationCard v-for="station in stations" @click.prevent="$router.push(`/station/${station.id}/details`)" :station="station" :key="station.id"/>
			</div>
			<div class="custom-list" v-else>Nothing to show</div>
		</div>
	</div>
</template>

<script>
import StationCard from '../../fragments/station/StationCard.vue'
import Navbar from '../../fragments/nav/Navbar.vue'
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { ROLES } from '../../../utils/constants'
export default {
    components: {StationCard, Navbar},
    setup () {
		const store = useStore()
		const stations = computed(() => store.getters['station/stations'])

		store.dispatch('station/getStations')
		store.dispatch('user/getUsersByRole', ROLES.op)
        return {
			stations
		}
    }
}
</script>

<style lang="scss" scoped>

</style>