<template>
    <div class="staff-container">
        <div class="head-with-back">
            <i
                class="fa fa-chevron-left"
                aria-hidden="true"
                @click.prevent="$router.go(-1)"
            ></i>
            <div class="heading">
                {{ editMode ? 'Edit Staff' : 'Add Staff' }}
            </div>
        </div>

        <div class="form">
            <div class="inputs-2">
                <input
                    type="text"
                    placeholder="Enter Staff name"
                    v-model="form.name"
                />
                <input
                    type="number"
                    placeholder="Enter ID number"
                    v-model="form.id_number"
                />
            </div>
            <div class="inputs-2">
                <select v-model="form.role">
                    <option value="">- Select Role -</option>
                    <option :value="ROLES.admin">Admin</option>
                    <option :value="ROLES.staff">Staff</option>
                    <option :value="ROLES.op">OP</option>
                </select>
                <select v-model="form.gender">
                    <option value="">- Select Gender -</option>
                    <option :value="GENDER.male">Male</option>
                    <option :value="GENDER.female">Female</option>
                    <option :value="GENDER.other">Other</option>
                </select>
            </div>
            <div class="inputs-2">
                <input
                    type="text"
                    placeholder="Allocated cell number"
                    v-model="form.allocated_contact_number"
                />
                <input
                    type="text"
                    placeholder="Next of kin number"
                    v-model="form.next_of_kin_contact_number"
                />
            </div>
            <div class="inputs">
                <input
                    type="text"
                    v-model="form.email"
                    placeholder="Email Address...."
                />
            </div>
            <div class="inputs">
                <input
                    type="text"
                    placeholder="Home Address"
                    v-model="form.address"
                />
            </div>
            <div class="inputs">
                <input
                    type="text"
                    placeholder="Mobile Tag"
                    v-model="form.mobile_tag"
                />
            </div>

            <div class="inputs">
                <div class="add-image">
                    <div class="image-container">
                        <div class="image-crop-area">
                            <VueCropper
                                ref="cropper"
                                :src="
                                    imgSrc
                                        ? imgSrc
                                        : require('@/assets/img/default.jpg')
                                "
                                alt="Source Image"
                                :viewMode="1"
                                :aspectRatio="1 / 1"
                                :dragMode="'none'"
                                :cropBoxResizable="false"
                            />
                        </div>

                        <div class="cropped-preview">
                            <img
                                :src="
                                    croppedImage
                                        ? croppedImage
                                        : form.half_image
                                        ? form.half_image
                                        : require('@/assets/img/default-cropped.jpg')
                                "
                                alt=""
                            />
                        </div>
                    </div>

                    <div class="select-image">
                        <input
                            type="file"
                            id="pick_image"
                            @change.prevent="selectImage($event)"
                            accept="image/*"
                            hidden
                        />
                        <button class="pick-image" @click.prevent="pickImage">
                            Select
                        </button>
                        <button @click.prevent="cropImage" class="crop-image">
                            Crop
                        </button>
                    </div>
                </div>
            </div>

            <div class="inputs">
                <button @click.prevent="saveData">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { ROLES, GENDER } from '../../../utils/constants';
import { base64ToFile } from '../../../utils/helper';
import {
    validAddress,
    validEmail,
    validName,
    validPhone
} from '../../../utils/validate';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import $ from 'jquery';
import _ from 'lodash';
import { errorToast, successToast } from '../../../utils/toast';
import { useStore } from 'vuex';
import router from '../../../router';
import { computed, watch } from '@vue/runtime-core';
export default {
    components: { VueCropper },
    setup() {
        const store = useStore();
        const userInfo = {
            name: '',
            id_number: '',
            gender: '',
            role: '',
            allocated_contact_number: '',
            next_of_kin_contact_number: '',
            email: '',
            address: '',
            mobile_tag: '',
            half_image: ''
        };
        const form = reactive({ ...userInfo });
        const user = computed(() => store.getters['user/user']);
        const id = ref(null);
        const editMode = ref(false);
        const imgSrc = ref(null);
        const cropper = ref(null);
        const croppedImage = ref(null);

        const pickImage = () => {
            const pickImg = $('#pick_image');
            pickImg.trigger('click');
        };

        const selectImage = e => {
            const file = e.target.files[0];

            var reader = new FileReader();
            reader.onloadend = function() {
                imgSrc.value = reader.result;
                cropper.value.replace(reader.result);
            };
            reader.readAsDataURL(file);
        };

        const cropImage = () => {
            croppedImage.value = cropper.value.getCroppedCanvas().toDataURL();
        };

        const saveData = async () => {
            const name = validName(form.name);
            const email = validEmail(form.email);
            const address = validAddress(form.address);
            const allocatedContact = validPhone(form.allocated_contact_number);
            const nextOfKinContact = validPhone(
                form.next_of_kin_contact_number
            );

            if (croppedImage.value || croppedImage.value != null) {
                form.half_image = croppedImage.value;
                const { prefix, data } = await base64ToFile(croppedImage.value);
                form.half_image = prefix + data;
            }

            if (!name.success) {
                errorToast(name.message);
                return;
            }

            if (!form.id_number || form.id_number.length < 13) {
                errorToast('Id number is 13 numbers');
                return;
            }

            if (!form.gender) {
                errorToast('Gender is required');
                return;
            }

            if (!form.role) {
                errorToast('Role is required');
                return;
            }

            if (!allocatedContact.success) {
                errorToast(allocatedContact.message);
                return;
            }

            if (!nextOfKinContact.success) {
                errorToast(nextOfKinContact.message);
                return;
            }

            if (!email.success) {
                errorToast(email.message);
                return;
            }

            if (!address.success) {
                errorToast(address.message);
                return;
            }

            if (!form.mobile_tag) {
                errorToast('Mobile Tag is required');
                return;
            }
            var data = { id: id.value, ..._.cloneDeep(form) };

            if (user.value.role != form.role) {
                data = { ...data, roleChange: true };
            }
            const { success, message } = editMode.value
                ? await store.dispatch('user/updateUser', data)
                : await store.dispatch('user/addUser', form);

            if (!success) {
                errorToast(message);
                return;
            }
            if (!editMode.value) {
                imgSrc.value = null
                cropper.value = null
                croppedImage.value = null
                Object.assign(form, userInfo);
            }

            successToast(message);
        };

        const setEditMode = async () => {
            const routerName = router.currentRoute.value.name;

            if (routerName == 'EditStaff') {
                editMode.value = true;
                id.value = router.currentRoute.value.params.id;
                await store.dispatch('user/getUser', id.value);
            } else {
                editMode.value = false;
            }
        };

        watch(user, () => {
            Object.assign(form, _.cloneDeep(user.value));
        });

        setEditMode();

        return {
            form,
            saveData,
            editMode,
            ROLES,
            GENDER,
            pickImage,
            imgSrc,
            selectImage,
            cropper,
            cropImage,
            croppedImage
        };
    }
};
</script>

<style lang="scss" scoped></style>
