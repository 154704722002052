<template>
    <div id="allocateStockModal" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="allocateStockModalLabel">Allocate Stock</h5>
                    <button type="button" class="btn-close" @click.prevent="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="items.length > 0">
                    <div>
                        <select v-model="item">
                            <option value="">- Select Item -</option>
                            <option v-for="itemData in items" :value="itemData" :key="itemData.id">{{`${itemData.name} | ${itemData.volume}${itemData.units}`}}</option>
                        </select>
                    </div>

                    <div v-if="item">
                        {{`Available Stock: ${availableStock} ${item.measurement}`}}
                    </div>

                    <div class="reactive-number-input" v-if="item">
                        <span @click="decrementCounter">-</span>
                        <input readonly type="number" name="" id="" min="0" :max="item.available_stock" v-model="form.available_stock"> <span @click="incrementCounter">+</span>
                    </div>

                    <div class="number-selector" v-if="item">
                        <div @click="addNumber(5)">5</div>
                        <div @click="addNumber(10)">10</div>
                        <div @click="addNumber(20)">20</div>
                        <div @click="addNumber(50)">50</div>
                        <div @click="addNumber(100)">100</div>
                        <div @click="resetNumber"> <i class="fa fa-refresh" aria-hidden="true"></i> </div>
                    </div>
                    <div v-if="item">
                        <button @click.prevent="allocateStock"> Save </button>
                    </div>
                </div>
            </div>           
        </div>

    </div>
</template>
<script>
import $ from 'jquery'
import { useStore } from 'vuex'
import { computed, reactive, ref, watch } from '@vue/runtime-core'
import { errorToast, successToast } from '../../utils/toast'
import router from '../../router'
import { auth } from '../../config/firebase'
import { ADD, REFILL_TYPE } from '../../utils/constants'
import _ from 'lodash'
export default {
    setup () {
        const store = useStore()
        const items = computed(() => store.getters['item/items'])
        const station = computed(() => store.getters['station/station'])
        const item = ref('')
        const formObj = {
            item_id: '',
            available_stock: 0,
            station_id: router.currentRoute.value.params.id,
            total_expected_revenue: 0,
            total_revenue_made: 0,
            refills: []
        }
        const form = reactive({...formObj})

        const availableStock = ref(0)
        const allocateStock = async () => {
            if (!item) {
                errorToast('Select an item to proceed')
                return 
            }

            if (form.available_stock <= 0) {
                errorToast('Quantity of goods to be allocated is required')
                return
            }
            const {id, price}  = _.cloneDeep(item.value)
            const { operator } = _.cloneDeep(station.value)
            const expectedRevenue = price * form.available_stock
            form.item_id = id
            form.total_expected_revenue = expectedRevenue
            form.refills = [{
                operator,
                amount: form.available_stock,
                refilled_by: auth.currentUser.uid,
                created_at: new Date(),
                type: REFILL_TYPE.add,
                expected_revenue: expectedRevenue
            }] 

            const { success, message } = await store.dispatch('stock/allocateStock', _.cloneDeep(form))

            if (!success) {
                errorToast(message)
                return
            }

            Object.assign(form, formObj)
            item.value = ''
            successToast(message)
        }

        const closeModal = () => {
            $('#allocateStockModal').hide('modal')
        }

        const incrementCounter = () => {
            if (availableStock.value > 0) {
                form.available_stock++
                availableStock.value--
            }
        }

        const decrementCounter = () => {
            if (form.available_stock > 0) {
                form.available_stock--
                availableStock.value++
            }
        }
        
        const addNumber = (numberToAdd) => {
            if (availableStock.value > 0) {
                const sum = form.available_stock + numberToAdd
                if (sum > _.cloneDeep(item.value).available_stock) {
                    errorToast('You give more than you have!!')
                    return
                }
                form.available_stock += numberToAdd
                availableStock.value -= numberToAdd
            }
        }

        const resetNumber = () => {
            form.available_stock = 0
            availableStock.value = _.cloneDeep(item.value).available_stock
        }
        watch(item, () => {
            resetNumber()
        })

        store.dispatch('item/getSellableItems')
        return {
            allocateStock,
            closeModal,
            items,
            item,
            form,
            incrementCounter,
            decrementCounter,
            availableStock,
            addNumber,
            resetNumber
        }
    }
}
</script>

<style lang="scss" scoped>

</style>