import { storage, firebase } from '../config/firebase';
import Compress from 'compress.js';
import moment from 'moment';
import _ from 'lodash';

export const statusHolder = (success = true, message = '') => {
    return {
        success,
        message
    };
};

export const categoryIcon = type => {
    switch (type) {
        case 'gas':
            return 'fa fa-fire';
        case 'fuel':
            return 'fa fa-gas-pump';
        default:
            return 'fa fa-toolbox';
    }
};

export const base64ToFile = async url => {
    const type = url.split(':')[1].split(';')[0];
    const res = await fetch(url);
    const blob = await res.blob();
    const file = new File(
        [blob],
        new Date().valueOf() + '.' + fileExtension(type),
        { type }
    );
    return await compressImage(file);
};

const fileExtension = type => {
    switch (type) {
        case 'image/png':
            return 'png';
        case 'image/jpg':
        case 'image/jpeg':
            return 'jpg';
    }
};

export const compressImage = async file => {
    const files = [file];
    const compress = new Compress();
    const compressedFile = await compress.compress(files, {
        quality: 0.75
    });

    return compressedFile[0];
};

export const uploadImage = async (full_path, base64Image) => {
    const base64String = base64Image.split(',')[1];
    const contentType = base64Image.split(':')[1].split(';')[0];
    const uploadTask = storage
        .ref()
        .child(full_path)
        .putString(base64String, 'base64', { contentType });

    uploadTask.on(
        firebase.default.storage.TaskEvent.STATE_CHANGED,
        ({ bytesTransferred, totalBytes }) => {
            let progress = Math.ceil((bytesTransferred / totalBytes) * 100);
            console.log(progress);
        }
    );

    return uploadTask.then(async snapshot => {
        const path = snapshot.ref.fullPath;
        const downloadLink = storage.ref(path).getDownloadURL();
        return downloadLink;
    });
};

// Time functions

export const dateFormat = date => {
    return date
        ? moment(_.cloneDeep(date).toDate()).format('D MMM Y')
        : 'Loading';
};

export const daysAgo = date => {
    return date ? moment(_.cloneDeep(date).toDate()).fromNow() : 'Loading';
};

export const timeDateFormatNow = () => {
    return moment(new Date()).format('D MMM Y, H:mm:ss');
};

// Generator

export const couponGenerator = () => {
    let numberString = '';
    let letterString = '';
    const MAX_NUMBERS = 4;
    const MAX_LETTERS = 3;

    for (let index = 0; index < MAX_NUMBERS; index++) {
        numberString += Math.floor(Math.random() * 9);
    }

    for (let index = 0; index < MAX_LETTERS; index++) {
        letterString += String.fromCharCode(
            65 + Math.floor(Math.random() * 26)
        );
    }
    let monthYear = moment(new Date()).format('MMYY');
    return 'SE-' + monthYear + letterString + numberString;
};
