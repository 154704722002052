import Item from './Item'
import Events from './Events'
import Station from './Station'
import { statusHolder } from '../utils/helper'
import {  STOCKS_REF, REFILL_REASON, REFILL_TYPE, COMPUTE_FIELD, ADD_TO_ARRAY } from '../utils/constants'
import { auth, firebase } from '../config/firebase'

class Stock {
    constructor() {
        this.events = new Events()
    }
    allocateStock = async ( form ) => {
        try {
            this.events.setLoading()
            const { item_id: id, available_stock: amount, station_id} = form
            const alreadyExists = await this.hasItem( id, station_id )
            if ( alreadyExists ) {
                this.events.resetLoading()
                return statusHolder(false, 'Item is already added')
            }
            const item = new Item()
            console.log(form);
            const { success, message } = await item.refillItem( { id, amount, type: REFILL_TYPE.subtract, reason: REFILL_REASON.station_allocation, station_id, expected_revenue: form.total_expected_revenue} )
            if ( !success ) {
                throw message
            }

            await STOCKS_REF.add( {...form, created_at: firebase.firestore.Timestamp.now()} )
            this.events.resetLoading()
            return statusHolder(true, 'Stock Allocated successfully')
        } catch ( error ) {
            this.events.resetLoading()
            return statusHolder(false, error.message)
        }
        
    }

    hasItem = async (itemId, stationId) => {
        const item = ( await STOCKS_REF.where( 'item_id', '==', itemId ).where( 'station_id', '==', stationId ).get() ).docs
        return item.length > 0 ? true : false
    }

    getStock = async ( id ) => {
        return (await STOCKS_REF.doc(id).get()).data()
    }

    // refillItem = async ( form ) => {
    //     try {
    //         const { id, station_id, amount, type, reason } = form
    //         const stock = await this.getStock( id )
    //         const stationObj = new Station()
    //         const { operator } = await stationObj.getStation(station_id)
    //         const { uid } = auth.currentUser
    //         var refillObj = {
    //             operator,
    //             amount,
    //             type,
    //             reason,
    //             created_at: new Date(),
    //             refilled_by: uid
    //         }

    //         let data = { available_stock: COMPUTE_FIELD( type == REFILL_TYPE.add ? amount : ( stock.available_stock <= 0 ? 0 : amount * -1 ) ) }
            
    //         if ( reason == REFILL_REASON.sold ) {
    //             refillObj = { ...refillObj, purchase_id: form.purchase_id }
    //             data = {...data, total_revenue_made: COMPUTE_FIELD(form.grand_total)}
    //         } else {
    //             refillObj = {...refillObj, expected_revenue: form.expectedRevenue}
    //         }
 
    //         data = {
    //             ...data,
    //             refills: stock.hasOwnProperty('refills') ? ADD_TO_ARRAY(refillObj): [refillObj]
    //         }
    //         this.events.setLoading()
    //         await STOCKS_REF.doc( id ).update( data )
    //         this.events.resetLoading()
    //         return statusHolder(true, `Stock refilled successfully`)
    //     } catch ( err ) {
    //         console.log(err);
    //         return statusHolder(false, err.message)
    //     }

        
    // }

    refillStock = async ( form ) => {
        try {
            this.events.setLoading()
            const item = new Item()
            const { id, item_id, amount, expected_revenue, station_id, type, reason } = form
            const itemData = await item.getItem( item_id )
            
            let stock = _.cloneDeep(await this.getStock( id ))
            if ( itemData.available_stock < amount ) {
                this.events.resetLoading()
                return statusHolder(false, `You only have ${itemData.available_stock} items available`)
            }
            const stationObj = new Station()
            const { operator } = await stationObj.getStation(station_id)
            const { uid } = auth.currentUser
            var refillObj = {
                operator,
                amount,
                type,
                reason,
                expected_revenue,
                created_at: new Date(),
                refilled_by: uid
            }

            const { success, message } = await item.refillItem( { id: item_id, amount, type: REFILL_TYPE.subtract, reason: REFILL_REASON.station_allocation, station_id, expected_revenue} )
            if ( !success ) {
                this.events.resetLoading()
                return statusHolder(false, message)
            }

            let data = { available_stock: COMPUTE_FIELD( type == REFILL_TYPE.add ? amount : ( stock.available_stock <= 0 ? 0 : amount * -1 ) ) }
            data = {
                ...data, expected_revenue: COMPUTE_FIELD( expected_revenue ), refills: stock.hasOwnProperty( 'refills' ) ? ADD_TO_ARRAY( refillObj ) : [refillObj]
            }

            await STOCKS_REF.doc( id ).update( {...data, updated_at: firebase.firestore.Timestamp.now()} )
            this.events.resetLoading()
            return statusHolder(true, 'Stock refilled')
        } catch ( error ) {
            return statusHolder(false, error.message)
        }


    }

}

export default Stock