<template>
    <div class="login">
        <div class="login-deco">
            <img src="@/assets/img/logo.png" height="100">
            <!-- Seskhona Energy -->
        </div>
        <div class="login-form">
            <div id="logo">
                 <img src="@/assets/img/logo.png" height="100">
            </div>
            <div class="inputs">
                <input type="email" v-model.trim="form.email" placeholder="Enter your email..."> <i class="fa fa-user"></i>
            </div>
            <div class="inputs">
                <input :type="showPassword ? 'text':'password'" v-model.trim="form.password" placeholder="Enter your password"> <i class="fa fa-eye" :class="showPassword && 'fa fa-eye-slash'" @click="showPassword = !showPassword"></i>
            </div>
            <div>
                <button type="button" @click.prevent="handleLogin">Login</button>
            </div>
            <div>
                <h6>Forgot password?</h6> &nbsp;&nbsp;<router-link  to="/">Reset</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { useStore } from 'vuex'
import { validPassword, validEmail } from '../../../utils/validate'
import router from '../../../router'
import { errorToast } from '../../../utils/toast'
export default {
    setup() {
        const form = reactive({
            email: '',
            password: ''
        })

        const store = useStore()
        const showPassword = ref(false)

        const handleLogin = async () => {
            const email = validEmail(form.email)
            const password = validPassword(form.password)

            if (!email.success) {
                errorToast(email.message)
                return 
            }

            if (!password.success) {
                errorToast(password.message)
                return
            }

            const {success, message} = await store.dispatch('user/login', form)

            if (!success) {
                errorToast(message)
                return
            }

            router.push('/home')
        } 

        return {
            form,
            handleLogin,
            showPassword
        }
    }
};
</script>
