export const addToCart = ( { commit }, form ) => {
    commit('ADD_TO_CART', form)
}

export const removeFromCart = ( { commit }, id ) => {
    commit('REMOVE_FROM_CART', id)
}

export const updateFromCart = ( { commit }, form ) => {
    commit('UPDATE_FROM_CART', form)
}

export const getCartItem = ( { commit }, id ) => {
    commit('GET_CART_ITEM', id)
}

export const clearCart = ( { commit } ) => {
    commit('CLEAR_CART')
}