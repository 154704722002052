<template>
<div class="center-page">
    <div class="custom-container">
        <div class="head-with-back">
            <i class="fa fa-chevron-left" aria-hidden="true" @click.prevent="$router.go(-1)"></i>
            <div class="heading">
                {{editMode ? 'Edit Station': 'Add Station'}}
            </div>
        </div>
        <div class="form" v-if="users.length > 0">
            <div class="inputs">
                <input type="text" placeholder="Station name..." v-model="form.name">
            </div>
            <div class="inputs">
                <input type="text" placeholder="Station location" v-model="form.location">
            </div>
            <div class="inputs">
                <select v-model="form.operator">
                    <option value="">- Select Operator -</option>
                    <option v-for="({name, id}) in users" :value="id" :key="id">{{name}}</option>
                </select>
            </div>
            <div class="inputs">
                <div class="caption">Station Status</div>
            </div>
            <div class="inputs">
                <label class="container-checkbox">{{STATION_STATUS.outgoing}}
                    <input type="radio" name="radio" v-model="form.status" @change.prevent="statusChange(STATION_STATUS.outgoing)" :checked="form.status === STATION_STATUS.outgoing">
                    <span class="checkmark"></span>
                </label>
                <label class="container-checkbox">{{STATION_STATUS.closed}}
                    <input type="radio" name="radio" v-model="form.status"
                    :checked="form.status === STATION_STATUS.closed" @change.prevent="statusChange(STATION_STATUS.closed)">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="inputs">
                <button @click.prevent="saveData">Save</button>
            </div>
        </div>
        <div class="custom-error" v-else>
            You have to add an operator before you can add a Station
        </div>
    </div>    
</div>

</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { ROLES, STATION_STATUS } from '../../../utils/constants'
import { useStore } from 'vuex'
import { computed, watch } from '@vue/runtime-core'
import { errorToast, successToast } from '../../../utils/toast'
import router from '../../../router'
import _ from 'lodash'
export default {
    setup () {
        const store = useStore()
        const users = computed(() => store.getters['user/users'])
        const station = computed (() => store.getters['station/station'])
        const editMode = ref(false)
        const stationObj = {
            name: '',
            operator: '',
            location: '',
            status: STATION_STATUS.outgoing
        }
        const id = ref(null)
        const routerName = ref(router.currentRoute.value.name)
        const form = reactive({...stationObj})

        store.dispatch('user/getUsersByRole', ROLES.op)

        const saveData = async () => {
            if (!form.name || form.name.length < 2) {
                errorToast('Station name should be at least 2 letters')
                return
            }

            if (!form.operator) {
                errorToast('Please select station operator to proceed')
                return
            }

            if (!form.location || form.location.length < 4) {
                errorToast('Station Location should be at least 4 letters')
                return
            }

            const { success, message } = await store.dispatch(editMode.value ? 'station/updateStation' :'station/addStation', form)
            if (!success) {
                errorToast(message)
                return
            }

            if (!editMode.value) {
                Object.assign(form, stationObj)
            }
            successToast(message)
        }

        const statusChange = status => {
            form.status = status
        }

        const checkRouter = async () => {
            if (routerName.value == 'EditStation') {
                editMode.value = true
                id.value = router.currentRoute.value.params.id
                await store.dispatch('station/getStation', id.value)
            }
        }

        watch(station, () => {
            const { created_at, creator, ...other } = _.cloneDeep(station.value)
            Object.assign(form, other)
        })

        checkRouter()

        return {
            editMode,
            form,
            STATION_STATUS,
            users,
            saveData,
            statusChange
        }
    }
}
</script>

<style lang="scss" scoped>

</style>