<template>
    <Loading :active="isLoading"
            :isFullPage="true"
            :color="'#FF8303'"
            :loader="'dots'"
            :width="80"
            :height="80"
            :backgroundColor="'#F0E3CA'"
            :opacity=".9"
            :zIndex="9999999"
    />
</template>

<script>
import { ref } from 'vue';
import Loading from 'vue-loading-overlay';
import { bus } from 'vue3-eventbus'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: {Loading},
    setup() {
        const isLoading = ref(false)
        bus.on('loading', value => {
            isLoading.value = value
            
        })
        return {
            isLoading
        }
    }
    
}
</script>
