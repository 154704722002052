<template>
    <div class="custom-item">
        <div class="item-info">
            <div>
                <div class="name">
                    {{`${stock.name} ${stock.volume}${stock.units}`}}
                </div>
                <div class="name">
                    <i class="fas fa-dolly-flatbed"></i> {{stock.available_stock}}
                </div>
                <div :class="revenue > 0 ? 'positive-reading' : 'negative-reading'">
                    {{revenue > 0 ? `+${revenue}`: (revenue == 0 ? 0 : `-${revenue}`)}}%
                </div>                                
            </div>
        </div>
        <div class="action-buttons">
            <button v-if="role.admin">Remove</button>
            <button v-if="role.admin" @click="refill">Refill</button>
            <button v-if="role.op" class="op-stock-action-button" @click="refillRequest">Request a refill</button>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import _ from 'lodash'
import { errorToast, successToast } from '../../../../utils/toast'
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE, SHOW_MODAL } from '../../../../utils/constants'
import { bus } from 'vue3-eventbus'
import $ from 'jquery'
export default {
    props: {
        stock: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const store = useStore()
        const stock = computed(()=> props.stock)
        const station = computed(() => store.getters['station/station'])
        const role = computed(() => store.getters['user/role'])
        const revenue = ref(0)

        watch (stock, () => {
            calculateRevenue()
        })

        const calculateRevenue = () => {
            const { total_expected_revenue, total_revenue_made } = stock.value
            revenue.value = Math.ceil(( total_revenue_made / total_expected_revenue ) * 100)
        }

        const refillRequest = async () => {
            const { id: station_id, operator } = _.cloneDeep(station.value)
            const { item_id } = _.cloneDeep(stock.value)
            
            let data = {
                from: operator,
                type: NOTIFICATION_TYPE.refillRequest,
                content: 'I am out of stock!',
                status: NOTIFICATION_STATUS.unread,
                station_id: station_id,
                item_id
            }

            const { success, message } = await store.dispatch('notification/addNotification', data)

            if (!success) {
                errorToast(message)
                return
            }

            successToast(message)
        }

        const refill = () => {
            bus.emit(SHOW_MODAL, stock.value)
            $('#refillItemModal').show('modal')
        }

        calculateRevenue()

        return {
            stock,
            revenue,
            role,
            refillRequest,
            refill
        }
    }
}
</script>

<style lang="scss" scoped>

</style>