export const cart = state => state.cart
export const cartTotal = state => {
    let sum = 0
    if ( state.cart.length > 0 ) {
        for (let index = 0; index < state.cart.length; index++) {
            const { total_price } = state.cart[index];
            sum+= parseFloat(total_price)
            
        }
    }
    return sum.toFixed(2)
}

export const cartItem = state => state.cartItem