<template>
    <div class="staff">
        <div class="staff-info">
            <div>
                <div class="name"> {{user.name}} </div>
                <div class="role"> {{user.role}} </div>
                <div class="date">{{dateFormat(user.created_at)}}</div> 
                <i class="fa" :class="user.disabled? 'fa-toggle-off': 'fa-toggle-on'" aria-hidden="true"></i>
            </div>
            <img :src="user.half_image || '@/assets/img/me.jpg'" >
            
        </div>
        
        <div class="action-buttons">
            <button>View</button>
            <button @click.prevent="$router.push(`/edit-staff/${user.id}`)">Edit</button>
            <button @click.prevent="deleteUser">Delete</button>
        </div>
        
    </div>	
</template>

<script>
import { computed } from '@vue/runtime-core'
import { dateFormat } from '../../../utils/helper'
import { useStore } from 'vuex'
import _ from 'lodash'
import { errorToast, successToast } from '../../../utils/toast'
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const store = useStore()
        const user = computed(() => props.user)

        const deleteUser = async () => {
            const { success, message } = await store.dispatch('user/deleteUser', _.cloneDeep(user.value).id)

            if (!success) {
                errorToast(message)
                return
            }
            successToast(message)
        }
        return {
            user,
            dateFormat,
            deleteUser
        }
    }
}
</script>

<style lang="scss" scoped>

</style>