<template>
    <div class="search-container">
        <div class="search">
            <input type="text" :placeholder="placeholder">
            <i class="fa fa-search" aria-hidden="true"></i>	
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import router from '../../../router'
import { watch } from '@vue/runtime-core'
export default {
    setup () {
        const placeholder = ref('')
        const routerName = ref('')
        
        const setupSearch = () => {
            routerName.value = router.currentRoute.value.name
            switch(routerName.value) {
                case 'CashRegister': 
                    placeholder.value = 'Search for an Item...'
                    break
                case 'Stations':
                    placeholder.value = 'Search for a station...'
                    break
                case 'Staff':
                    placeholder.value = 'Search for staff member...'
                    break
                default:
                    placeholder.value = 'To be set...'
                    break
            }
        }
        
        watch(router, () => {
            setupSearch()
        })

        setupSearch()
        return {
            placeholder
        }
    }
}
</script>

<style lang="scss" scoped>

</style>