<template>
    <div id="navbar">
        <div class="collapse" id="navbarToggleExternalContent">
            <div class="p-4">
                <div><i class="fa fa-user"></i> <span>Profile</span></div>
                <div><i class="fa fa-cog"></i> <span>Settings</span></div>
                <div @click.prevent="logout">
                    <i class="fa fa-sign-out"></i> <span>Logout</span>
                </div>
            </div>
        </div>
        <nav class="navbar sticky-top">
            <div class="container-fluid">
                <router-link to="/home" class="navbar-brand">
                    <img src="@/assets/img/logo.png" width="50" />
                </router-link>
                <div class='navbar-actions'>
                    <div><i class="fa fa-user"></i></div>
                    <div @click.prevent="logout">
                        <i class="fa fa-sign-out"></i>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import router from '../../../router';
export default {
    setup() {
        const store = useStore();

        const logout = async () => {
            const { success, message } = await store.dispatch('user/logout');

            if (!success) {
                console.error(message);
                return;
            }

            router.replace('/');
        };
        return {
            logout
        };
    }
};
</script>

<style lang="scss" scoped></style>
