import { statusHolder } from './helper'
const validPassword = ( password ) => {
    if ( password.length == 0 ) {
        return statusHolder(false, 'Password is required')
    }

    if ( password.length < 5 ) {
        return statusHolder(false, 'Password should be 5 digits')
    }
    return statusHolder()
}

const validAddress = ( address ) => {
    if ( address.length < 6 ) {
        return statusHolder(false, 'Address should be at least 6 characters')
    }

    return statusHolder()
}

const validEmail = ( email ) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( email.length < 5 ) {
        return statusHolder(false, 'Email should be at least 5 characters')
    } else if ( !re.test( email.toLowerCase() ) ) {
        return statusHolder(false, 'Please provide a valid email address')
    }
    return statusHolder();
}
const validPhone = ( phone ) => {
    if ( phone.length < 10 ) {
        return statusHolder(false, 'Contact number should be at least 10 digits')
    }

    if ( !phone.startsWith('+') ) {
        return statusHolder(false, 'Phone number is like +27814229072')    
    }

    if (isNaN(phone.substring(1))) {
        return statusHolder(false, 'No letters allowed after +')    
    }

    return statusHolder()
}

const validFile = ( { name, size } ) => {
    const nameArr = name.split( '.' )
    const fileExtension = nameArr[nameArr.length - 1]
    let fileExtArray = [
        'png', 'jpg', 'pdf', 'rtf', 'txt', 'zip'
    ]
    let cleanFileExtension = fileExtension.toLowerCase()

    if (!fileExtArray.includes(cleanFileExtension) && !cleanFileExtension.startsWith('doc')
    && !cleanFileExtension.startsWith('dot') && !cleanFileExtension.startsWith('xls')
    && !cleanFileExtension.startsWith('xlt') && !cleanFileExtension.startsWith('xlw')
    && !cleanFileExtension.startsWith('pp') && !cleanFileExtension.startsWith('po')) {
        return statusHolder(false, 'File format is not supported')
    }

    if ( size > 50000000 ) {
    return statusHolder( false, 'File should not be larger than 50Mb')
    }
    return statusHolder()
}

const validName = ( name ) => {
    
    if ( !name ) {
        return statusHolder(false, 'Please enter your name')
    }
    
    const nameArr = name.split( ' ' )
    
    if ( nameArr.length != 2 ) {
        return statusHolder(false, 'Invalid!, full name is like "John Doe"')
    }

    return statusHolder()
}

export {
  validPassword,
  validAddress,
  validName,
  validPhone,
  validFile,
  validEmail
}