<template>
    <div class="notification">
        <div class="notification-info">
            <span>{{notification.name}}</span>
            <span>{{daysAgo(notification.created_at)}}</span>
        </div>
        <div class="notification-content" :class="showMoreText? 'show-more-text': ' hide-more-text'" >
            {{notification.content}}
        </div>
        <div class="notification-more-text" v-if="notification.content.length > 20">
            <i class="fa" :class="showMoreText ? 'fa-minus' :'fa-plus'" aria-hidden="true" @click="showMoreText = !showMoreText"></i>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { daysAgo } from '../../../utils/helper'
export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const notification = computed(() => props.notification)
        const showMoreText = ref(false)
        return {
            showMoreText,
            notification,
            daysAgo
        }
    }
}
</script>