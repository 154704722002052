<template>
    <div class="notifications-container">

        <Navbar />
        <div class="custom-container">
            <back-with-arrow :title="'Notifications'" />
        </div>
        
        <div class="notifications-list" v-if="notifications.length > 0">
            <Notification v-for="notification in notifications" :notification="notification" :key="notification.id"/>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Navbar from '../../fragments/nav/Navbar.vue'
import Notification from '../../fragments/notification/Notification.vue'
import BackWithArrow from '../../fragments/nav/BackWithArrow.vue'
export default {
	components: { Notification, Navbar, BackWithArrow },
    setup () {

        const store = useStore()
        const notifications = computed(() => store.getters['notification/notifications'])
        store.dispatch('notification/getNotifications')
        return {
            notifications
        }
    }
}
</script>