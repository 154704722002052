<template>
    <div class="header">
        <i
            @click="$router.go(-1)"
            class="fa fa-arrow-left"
            aria-hidden="true"
        ></i>
        <h5>{{ editMode ? 'Edit Mode' : 'Add Item' }}</h5>
    </div>
    <div id="add_item">
        <div v-if="step == 1" class="inputs">
            <h5>Item info</h5>
            <div>
                <input
                    type="text"
                    v-model="form.name"
                    placeholder="Item name..."
                />
            </div>
            <div>
                <select v-model="form.category">
                    <option value="">- Category -</option>
                    <option
                        v-for="{ name, id } in categories"
                        :value="id"
                        :key="id"
                        >{{ name }}</option
                    >
                </select>
            </div>
            <div id="item_volume">
                <span>Item volume and measure</span>
                <div>
                    <input
                        type="number"
                        placeholder="Volume"
                        v-model="form.volume"
                    />
                    <select v-model="form.units">
                        <option value="" disabled>- Units -</option>
                        <option value="l">Litres</option>
                        <option value="ml">Milli litres</option>
                        <option value="kg">Kilo grams</option>
                        <option value="g">Grams</option>
                        <option value="qty">Quantity</option>
                        <option value="box">Boxes</option>
                        <option value="box">Bags</option>
                        <option value="pkt">Packets</option>
                    </select>
                    <select v-model="form.measurement">
                        <option value="" disabled>- Measure -</option>
                        <option value="qty">Quantity</option>
                    </select>
                </div>
            </div>
            <div class="checkout-input">
                <input type="checkbox" v-model="form.sellable" />
                <span>is the item sellable?</span>
            </div>
            <div v-if="form.sellable">
                <input
                    type="number"
                    v-model="form.price"
                    placeholder="Item price"
                />
            </div>
        </div>

        <div v-if="step == 2" class="inputs">
            <h5>Packaging info</h5>
            <div class="container-price">
                <div>
                    <label>Container price</label>
                    <input
                        type="number"
                        v-model="form.container_price"
                        placeholder="Container price"
                    />
                </div>
                <div>
                    <label>Cap / Lid price</label>
                    <input
                        type="number"
                        v-model="form.lid_price"
                        placeholder="Cap/Lid price"
                    />
                </div>
                <div>
                    <label>Branding price</label>
                    <input
                        type="number"
                        v-model="form.branding_price"
                        placeholder="Branding price"
                    />
                </div>
            </div>
        </div>

        <div v-if="step == 0" class="inputs">
            <div class="container-price">
                <div>
                    <label>Item margin</label>
                    <input
                        type="number"
                        v-model="form.item_margin"
                        placeholder="Item Margin(e.g) 5 if 5 bottles of 2 litre paraffin"
                    />
                </div>                
            </div>

        </div>

        <div class="inputs" id="button">
            <a @click.prevent="prevStep" v-if="step != 1 || 0">
                <i class="fa fa-chevron-left" aria-hidden="true"></i> Back</a
            >
            <button @click.prevent="step == 0 ? submitData() : nextStep()">
                {{ step == 0 ? 'Save' : 'Next' }}
            </button>
            <button @click.prevent="submitData" v-if="editMode && step != 0">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { errorToast, successToast } from '../../../utils/toast';
import _ from 'lodash';
import router from '../../../router';
export default {
    setup() {
        const store = useStore();
        const categories = computed(() => store.getters['category/categories']);
        const item = computed(() => store.getters['item/item']);


        const step = ref(1);
        const obj = {
            name: '',
            category: '',
            price: '',
            volume: '',
            units: '',
            measurement: '',
            available_stock: 0,
            sellable: true,
            container_price: '',
            lid_price: '',
            branding_price: '',
            item_margin: '',
            total_expected_revenue: 0,
            total_revenue_made: 0
        };

        const form = reactive({ ...obj });

        const routerName = ref(router.currentRoute.value.name);

        const containerPrice = ref('');
        const editMode = ref(false);

        const nextStep = () => {
            step.value == 2 ? (step.value = 0) : step.value++;
        };

        const prevStep = () => {
            step.value == 0 ? (step.value = 2) : step.value--;
        };

        const submitData = async () => {
            let data = form;
            if (!form.name || form.name.length < 2) {
                step.value = 1
                errorToast('Name should be at least 2 letters');
                return;
            }

            if (!form.category) {
                step.value = 1
                errorToast('Category is required');
                return;
            }

            if (form.sellable && (!form.price || form.price == 0)) {
                step.value = 1
                errorToast('Provide a price for this item');
                return;
            }

            if (!form.volume) {
                step.value = 1
                errorToast('Provide a volume');
                return;
            }

            if (!form.measurement) {
                step.value = 1
                errorToast('Provide item measurement');
                return;
            }

            if (!form.units) {
                step.value = 1
                errorToast('Provide units');
                return;
            }

            if (!form.container_price) {
                form.container_price = 0.00
            }
            if (!form.lid_price) {
                form.lid_price = 0.00
            }
            if (!form.branding_price) {
                form.branding_price = 0.00
            }

            const { success, message } = editMode.value
                ? await store.dispatch('item/editItem', {
                      id: router.currentRoute.value.params.id,
                      ..._.cloneDeep(form)
                  })
                : await store.dispatch('item/addItem', _.cloneDeep(form));

            if (!success) {
                errorToast(message);
                return;
            }

            if (!editMode.value) {
                step.value = 1;
                Object.assign(form, obj);
            }
            successToast(message);
        };

        const getItem = async () => {
            const { id } = router.currentRoute.value.params;
            await store.dispatch('item/getItem', id);
        };

        watch(item, () => {
            let { refills, ...other } = _.cloneDeep(item.value);
            Object.assign(form, other);
        });

        if (routerName.value == 'EditItem') {
            editMode.value = true;
            getItem();
        }

        store.dispatch('category/getCategories');
        return {
            categories,
            step,
            form,
            prevStep,
            nextStep,
            submitData,
            editMode
        };
    }
};
</script>
