<template>
    <div id="selectCartItemModal" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="selectCartItemModalLabel">{{editMode? 'Edit: ' : 'Add: '}}{{`${form.name} ${form.volume}${form.units}`}}</h5>
                    <button type="button" class="btn-close" @click.prevent="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="label">
                        <span>Available Stock: {{availableStock}}</span>
                    </div>

                    <div class="cart-navigate">
                        <button :class="selectedButton == 0 && 'active'" @click="selectButton(0)">
                            <span>Items</span><span class="counter">{{form.item_quantity}}</span>
                        </button>
                    </div>
                    <div class="label" v-if="form.container_price > 0 && form.item_quantity > 0">
                        <span>Containers</span>
                    </div>
                    <div class="cart-navigate" v-if="form.container_price > 0 && form.item_quantity > 0">
                        <button :class="selectedButton == 1 && 'active'" @click="selectButton(1)">
                            <span>Unavailable</span><span class="counter">{{form.unavailable_container_quantity}}</span>
                        </button>
                        <button :class="selectedButton == 2 && 'active'" @click="selectButton(2)" v-if="form.unavailable_container_quantity > 0">
                            <span>Unbranded</span><span class="counter">{{form.unbranded_container_quantity}}</span>
                        </button>
                    </div>

                    <div class="reactive-number-input">
                        <span @click="decrementCounter()">-</span>
                        <input readonly type="number" name="" id="" min="0"  v-model="dynamicNumber"> <span @click="incrementCounter()">+</span>
                    </div>

                    <div class="number-selector">
                        <div v-for="(number, index) in incrementNumbers" @click="addNumber(number)" :key="index">{{number}}</div>
                        <div @click="resetNumber"> <i class="fa fa-refresh" aria-hidden="true"></i> </div>
                    </div>
    
                    <div>
                        <button @click="editMode ? updateFromCart() :addToCart()">{{editMode? 'Update Cart': 'Add to Cart'}}</button>
                    </div>
                </div>
            </div>           
        </div>

    </div>
</template>

<script>
import { bus } from 'vue3-eventbus'
import _ from 'lodash'
import $ from 'jquery'
import { REFILL_TYPE, SHOW_MODAL } from '../../utils/constants'
import { reactive, ref } from '@vue/reactivity'
import { errorToast } from '../../utils/toast'
import { useStore } from 'vuex'
import { computed, watch } from '@vue/runtime-core'
export default {
    setup () {
        const availableStock = ref(0)
        const store = useStore()
        const cartItem = computed(() => store.getters['cart/cartItem'])
        const role = computed(() => store.getters['user/role'])
        const editMode = ref(false)
        const incrementNumbers = [2, 5, 10, 15, 20];
        const selectedButton = ref(0)
        // dyncamic number is used for increment and decrement fn
        const dynamicNumber = ref(0)
        const form = reactive({
            id: '',
            name: '',
            volume: 0,
            units: '',
            price: 0,
            item_quantity: 0,
            container_price: 0,
            branding_price: 0,
            available_stock: 0,
            unavailable_container_quantity: 0,
            unbranded_container_quantity: 0,
            total_price: 0
        })

        const before = reactive({
            item_quantity: 0,
            container_quantity: 0,
            unbranded_container_quantity: 0
        })
        const closeModal = () => {
            $('#selectCartItemModal').hide('modal')
            selectButton.value = 0;
            form.unbranded_container_quantity = 0;
            form.unavailable_container_quantity = 0;
            form.item_quantity = 0
        }
        bus.on(SHOW_MODAL, (data) => {
            const item = _.cloneDeep(data)
            setForm(item)
            bus.off(SHOW_MODAL)
        })

        const setForm = async (item) => {
            const { id, name, volume, units, price, container_price,  available_stock, branding_price } = item
            form.item_quantity = 0
            form.unavailable_container_quantity = 0
            form.unbranded_container_quantity = 0
            form.id = id;
            form.name = name;
            form.volume = volume;
            form.units = units;
            form.price = price;
            form.container_price = container_price
            form.branding_price = branding_price
            form.available_stock = available_stock;
            availableStock.value = available_stock
            editMode.value = item.editMode
            console.log(item);
            await store.dispatch('cart/getCartItem', id)
        }
        const incrementCounter = () => {
            switch(selectedButton.value) {
                case 0:
                    if (availableStock.value > 0) {
                        form.item_quantity++
                        availableStock.value--
                        if (form.unavailable_container_quantity > form.item_quantity) {
                            form.unavailable_container_quantity = form.item_quantity
                        }
                    }
                    dynamicNumber.value = form.item_quantity
                    break;
                case 1:
                    if (form.unavailable_container_quantity < form.item_quantity) {
                        form.unavailable_container_quantity++
                        
                    } else {
                        if (form.unavailable_container_quantity == form.item_quantity) {
                            form.unbranded_container_quantity = 0
                        }
                        errorToast('Available containers should be less than or equal to item')
                        return
                    }

                    dynamicNumber.value = form.unavailable_container_quantity
                    break;
                case 2:
                    if (form.unbranded_container_quantity < form.item_quantity - form.unavailable_container_quantity) {
                        form.unbranded_container_quantity++
                    } else {
                        errorToast('Branded containers should be less than or equal to Available containers')
                        return
                    }

                    dynamicNumber.value = form.unbranded_container_quantity
                    break;
            }
        }

        const decrementCounter = () => {
            switch(selectedButton.value) {
                case 0:
                    if (form.item_quantity > 0) {
                        form.item_quantity--
                        availableStock.value++

                        if (form.unavailable_container_quantity > form.item_quantity) {
                            form.unavailable_container_quantity = form.item_quantity
                        }
                    }
                    dynamicNumber.value = form.item_quantity
                    break;
                case 1:
                    if (form.unavailable_container_quantity > 0) {
                        form.unavailable_container_quantity--
                        if (form.unavailable_container_quantity == form.item_quantity) {
                            form.unbranded_container_quantity = 0
                        }
                    } else {
                        errorToast('Available containers should be less than or equal to item')
                        return
                    }
                    dynamicNumber.value = form.unavailable_container_quantity
                    break;
                case 2:
                    if (form.unbranded_container_quantity > 0) {
                        form.unbranded_container_quantity--
                    } else {
                        errorToast('Branded containers should be less than or equal to Available containers')
                        return
                    }
                    dynamicNumber.value = form.unbranded_container_quantity
                    break;
            }
        }

        const addNumber = ( number ) => {
            switch (selectedButton.value) {
                case 0:
                    if (availableStock.value > 0) {
                        const sum = form.item_quantity + number
                        if (sum > form.available_stock) {
                            errorToast('You cannot sell more than you have')
                            return
                        }
                        form.item_quantity += number
                        availableStock.value -= number

                        if (form.unavailable_container_quantity > form.item_quantity) {
                            form.unavailable_container_quantity = form.item_quantity
                        }
                        dynamicNumber.value = form.item_quantity
                    }
                break;
                case 1:
                    if (form.item_quantity >= form.unavailable_container_quantity) {
                        const sum = form.unavailable_container_quantity + number
                        if (sum > form.item_quantity) {
                            errorToast('You cannot sell more containers than items')
                            return
                        }
                        form.unavailable_container_quantity += number

                        if (form.unavailable_container_quantity == form.item_quantity) {
                            form.unbranded_container_quantity = 0
                        }

                        dynamicNumber.value = form.unavailable_container_quantity
                    }
                break;
                case 2:
                    if (form.unavailable_container_quantity >= form.unbranded_container_quantity) {
                        const sum = form.unbranded_container_quantity + number
                        if (sum > form.unavailable_container_quantity) {
                            errorToast('You cannot sell more containers than items')
                            return
                        }
                        form.unbranded_container_quantity += number
                        dynamicNumber.value = unbranded_container_quantity
                    }
                break;

            }

        }

        const resetNumber = () => {
            switch(selectedButton.value) {
                case 0:
                    form.item_quantity = 0
                    form.unavailable_container_quantity = 0
                    form.unbranded_container_quantity = 0
                    availableStock.value = form.available_stock
                    dynamicNumber.value = 0
                break;
                case 1:
                    form.unavailable_container_quantity = 0
                    form.unbranded_container_quantity = 0
                    dynamicNumber.value = 0
                break;
                case 2:
                    form.unbranded_container_quantity = 0
                    dynamicNumber.value = 0
                break;
            }
        }

        const addToCart = async () => {
            if (form.item_quantity == 0) {
                errorToast('Select at least one item')
                return
            }
            store.dispatch('cart/addToCart', form)
            const { staff, op} = role.value
            store.dispatch(staff && !op ? 'item/updateAvailableStock': 'stock/updateAvailableStock', {id: form.id, available_stock: availableStock.value})
            selectButton(0)
            $('#selectCartItemModal').hide('modal')

        }

        const updateFromCart = () => {
            const {id, item_quantity, unavailable_container_quantity, unbranded_container_quantity } = _.cloneDeep(form) 
            store.dispatch('cart/updateFromCart', {id, item_quantity, unavailable_container_quantity, unbranded_container_quantity})
            if (before.item_quantity != form.item_quantity) {
                const { staff, op } = role.value
                store.dispatch(staff && !op ? 'item/updateAvailableStock': 'stock/updateAvailableStock', {id: form.id, available_stock: availableStock.value })                
            }
            selectButton(0)

            $('#selectCartItemModal').hide('modal')
        }
        

        watch(cartItem, () => {
            if (cartItem.value) {
                if (editMode.value) {
                    const _cartItem = _.cloneDeep(cartItem.value)
                    console.log(_cartItem);
                    form.item_quantity = _cartItem.item_quantity
                    form.unavailable_container_quantity = _cartItem.unavailable_container_quantity
                    form.unbranded_container_quantity = _cartItem.unbranded_container_quantity

                    before.item_quantity = form.item_quantity
                    before.unavailable_container_quantity = form.unavailable_container_quantity
                    before.unbranded_container_quantity = form.unbranded_container_quantity
                    availableStock.value -= form.item_quantity                    
                }
            }
        })

        const selectButton = (num) => {
            selectedButton.value = num

            switch(num) {
                case 0:
                    dynamicNumber.value = form.item_quantity
                break
                case 1:
                    dynamicNumber.value = form.unavailable_container_quantity
                break;
                case 2:
                    dynamicNumber.value = form.unbranded_container_quantity
                break
            }
            
        }

        return {
            closeModal,
            form,
            incrementCounter,
            decrementCounter,
            addNumber,
            resetNumber,
            addToCart,
            updateFromCart,
            cartItem,
            availableStock,
            editMode,
            incrementNumbers,
            selectedButton,
            selectButton,
            dynamicNumber
        }
    }
}
</script>

<style lang="scss" scoped>

</style>