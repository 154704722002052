import { STATIONS_REF } from '../utils/constants'
import Events from './Events'
import { statusHolder } from '../utils/helper'
import { auth, firebase} from '../config/firebase'

class Station {
    constructor() {
        this.stationRef = STATIONS_REF
        this.events = new Events()
    }

    addStation = async ( form ) => {
        try {
            this.events.setLoading()
            const currentTime = firebase.default.firestore.Timestamp.now()
            await this.stationRef.add( { ...form, created_at: currentTime, creator: auth.currentUser.uid } )
            this.events.resetLoading()
            return statusHolder(true, 'Station added')
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }

    updateStation = async ( form ) => {
        try {
            const { id, ...other} = form
            this.events.setLoading()
            const currentTime = firebase.default.firestore.Timestamp.now()
            await this.stationRef.doc(id).update( { ...other, updated_at: currentTime } )
            this.events.resetLoading()
            return statusHolder(true, 'Station updated succesfully!!')
        } catch ( error ) {
            this.events.resetLoading()
            return statusHolder(false, error.message)
        }
    }

    deleteStation = async ( id ) => {
        try {
            this.events.setLoading()
            await this.stationRef.doc(id).delete()
            this.events.resetLoading()
            return statusHolder(true, 'Station deleted')
        } catch ( error ) {
            this.events.resetLoading()
            return statusHolder(false, error.message)
        }
    }

    getStationOfCurrentUser = async () => {
        return (await STATIONS_REF.where('operator', '==', auth.currentUser.uid).get()).docs
    }

    getStation = async (id) => {
        return (await STATIONS_REF.doc(id).get()).data()
    }
}

export default Station