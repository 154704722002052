<template>
	<div class="staff-container">
		<Navbar />
		<div class="head">
			<div class="heading">Staff</div>
			<i class="fa fa-user-plus" aria-hidden="true" @click.prevent="$router.push('/add-staff')"></i>
		</div>
		<div class="search-container">
			<div class="search">
				<input type="text" placeholder="search for a staff here...">
				<i class="fa fa-search" aria-hidden="true"></i>	
			</div>
		</div>
		<div class="staff-list-container">
			<div class="staff-list" v-if="users.length > 0">
				<Staff v-for="user in users" :user="user" :key="user.id"/>
			</div>

			<h4 v-else class="staff-list text-muted">
				No one here...
			</h4>
		</div>
	</div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Navbar from '../../fragments/nav/Navbar.vue';
import Staff from '../../fragments/user/Staff.vue'
import { errorToast } from '../../../utils/toast';
export default {
	components: {Navbar, Staff },
	setup() {
		const store = useStore()
		const users = computed(() => store.getters['user/users'])
		
		const getUsers = async () => {
			const { success, message } = await store.dispatch('user/getUsers')
			if (!success) {
				errorToast(message)
			}
		}

		getUsers()
		return {
			users
		};
	},
};
</script>

<style lang="scss" scoped>
</style>