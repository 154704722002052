
import { useToast } from 'vue-toastification'

const toast = useToast()
const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.49,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
}

export const successToast = ( message ) => {
    toast.success(message, options)
}
export const errorToast = ( message ) => {
    toast.error(message, options)
}
export const warningToast = ( message ) => {
    toast.warning(message, options)
}
export const infoToast = ( message ) => {
    toast.info(message, options)
}