<template>
    <div @click.prevent="showModal">
        <i :class="categoryIcon(category.type)"></i>
        <p>{{category.name}}</p>
    </div>    
</template>

<script>
import { categoryIcon } from '../../../utils/helper'
import { bus } from 'vue3-eventbus'
import { SHOW_MODAL } from '../../../utils/constants' 
import $ from 'jquery'
import { computed, ref, watch } from '@vue/runtime-core'
export default {
    props: {
        category: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        
        const category = computed(() => props.category)
        const showModal = () => {
            bus.emit(SHOW_MODAL, category.value)
            $('#categoryActionsModal').show('modal')
        }

        // watch(props, (newValue) => {
        //     category.value = newValue.category
        // }) 

        return {
            categoryIcon,
            category,
            showModal
        }
    }
}
</script>

<style lang="scss" scoped>

</style>