<template>
    <div class="seskhona-container">
        <Navbar />
        <Navigation />
    </div>
</template>

<script>
import Navigation from '../fragments/nav/Navigation.vue'
import Navbar from '../fragments/nav/Navbar.vue'
export default {
  components: { Navigation, Navbar},
    setup () {
        return {}
    }
}
</script>