import { USERS_REF } from '../../utils/constants'

export const GET_STATIONS = async ( state, stations ) => {
    const operators = []
    const stationsArray = stations.map( station => {
        return {
            id: station.id,
            ...station.data()
        }
    } )
    
    for (let index = 0; index < stationsArray.length; index++) {
        const element = stationsArray[index];
        const user = await USERS_REF.doc(element.operator).get()
        if ( user.exists ) {
            const { name } = user.data()
            operators.push({name, id: user.id})
        }
    }

    state.stations = stationsArray.map( station => {
        const user = operators.filter( ( { id } ) => id == station.operator )
        if ( user.length > 0 ) {
            delete user[0].id
        }
        const operator_name = user.length > 0 ? user[0].name : 'No Operator'
        return {
            id: station.id,
            ...station,
            operator_name
        }
    })
}

export const GET_STATION = async ( state, station ) => {

    const user = await USERS_REF.doc( station.data().operator ).get()
    let operator_name = 'No Operator'
    if ( user.exists ) {
        operator_name = user.data().name
    }
    state.station = {
        id: station.id,
        ...station.data(),
        operator_name
    }
}