import { firestore} from '../config/firebase'
import { CATEGORIES } from '../utils/constants'
import { statusHolder } from '../utils/helper'
import Events from './Events'
import Item from './Item'

class Category {
    constructor() {
        this.categoryRef = firestore.collection( CATEGORIES )
        this.events = new Events()
    }

    addCategory = async ( form ) => {
        try {
            this.events.setLoading()
            await this.categoryRef.add( form )
            // this.events.resetLoading()
            return statusHolder(true, 'Category added succesfully')
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)    
        }
    }

    editCategory = async ( form ) => {
        try {
            this.events.setLoading()
            const { id, ...other } = form
            await this.categoryRef.doc(id).update({...other})
            return statusHolder(true, 'Category updated succesfully')
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)   
        }
    }
    getCategory = async ( id ) => {
        try {
            this.events.setLoading()
            await this.categoryRef.doc( id ).get()
            this.events.resetLoading()
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)    
        }
    }
    deleteCategory = async ( id ) => {
        try {
            // const item = new Item()
            this.events.setLoading()
            await this.categoryRef.doc( id ).delete()
            // const items = await item.getItemsByCategory( id )
            // if ( items.length > 0 ) {
            //     items.forEach( async ( { id } ) => {
            //         await item.deleteItem(id)
            //     })   
            // }
            setTimeout(() => {
                this.events.resetLoading()
            }, 1000);
            return statusHolder(true, 'Category deleted')
        } catch ( err ) {
            this.events.resetLoading()
            return statusHolder(false, err.message)
        }
    }
    
}

export default Category