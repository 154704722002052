import { auth } from '../../config/firebase'

export const GET_USERS = ( state, data ) => {
    const users = data.filter( user => user.id != auth.currentUser.uid )
    state.users = users.map( user => {
        return {
            id: user.id,
            ...user.data()
        }
    })
}

export const GET_USER = ( state, user ) => {
    state.user = {
        id: user.id,
        ...user.data()
    }
}

export const GET_USER_ROLE = ( state, claims ) => {
    state.role = {
        admin: claims.admin,
        staff: claims.staff,
        op: claims.op
    }
}