<template>
    <div class="form" v-if="items.length > 0">
        <div class="inputs">
            <select name="" id="">
                <option value=""> Select an Item</option>
                <option :value="id" v-for="({id, name, volume, units}) in items" :key="id">{{`${name} ${volume}${units}`}}</option>
            </select>
        </div>

        <div class="inputs">
            <input type="number" name="" placeholder="Purchases to win">
        </div>
        <div class="inputs">
            <input type="number" name="" placeholder="How many you get?">
        </div>

        <div class="inputs">
            <button>Save</button>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
    setup () {
        const store = useStore()
        const items = computed(() => store.getters['item/items'])

        store.dispatch('item/getItems')
        return {
            items
        }
    }
}
</script>

<style lang="scss" scoped>

</style>