<template>
    <div class="modal" id="addCategoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
            <div class="modal-header border-0">
                <h5 class="modal-title" id="addCategoryModalLabel">{{editMode? 'Edit Category' :'Add Category'}}</h5>
                <button @click.prevent="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <input type="text" v-model="form.name" placeholder="Category name...">
                </div>
                <div>
                    <select v-model="form.units">
                        <option value="">- Assign units -</option>
                        <option value="l">Litres</option>
                        <option value="kg">Kilo grams</option>
                        <option value="g">Grams</option>
                        <option value="qty">Quantity</option>
                    </select>
                </div>
                <div>
                    <select v-model="form.type">
                        <option value="">- Select Category type -</option>
                        <option value="fuel"> Fuel </option>
                        <option value="gas"> Gas</option>
                        <option value="kits">Operator kits</option>
                        <!-- <option value=""></option> -->
                    </select>
                </div>
                <div>
                    <input type="checkbox" v-model="form.contains_sellables"> <span>contains sellable items?</span>
                </div>
                <div>
                    <button @click.prevent="saveCategory"> Save </button>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { errorToast, successToast } from '../../utils/toast'
import { computed } from '@vue/runtime-core'
import { bus } from 'vue3-eventbus'
import { SHOW_MODAL } from '../../utils/constants'
import _ from 'lodash'
import $ from 'jquery'
export default {
    setup () {
        const form = reactive({
            name: '',
            units: '',
            type: '',
            contains_sellables: true
        })

        const id = ref('')
        const editMode = ref(false)

        const store = useStore()
        const categories = computed(()=> store.getters['category/categories'])
        const saveCategory = async () => {
            let checkCategory = null

            if (editMode.value) {
                checkCategory = categories.value.filter(({id: categoryId, name}) => categoryId != id.value && name == form.name )
            } else {
                checkCategory = categories.value.filter(({name}) => form.name == name)
            }

            if (checkCategory.length > 0) {
                errorToast('Category already exists')
                return
            }

            const { success, message } = editMode.value ? await store.dispatch('category/editCategory', {id: id.value, ..._.cloneDeep(form)}) : await store.dispatch('category/addCategory', form)
            if (!success) {
                errorToast(message)
                return
            }
            
            if (!editMode.value) {
                form.name = '', form.units = '', form.type = ''
                form.contains_sellables = true    
            }

            successToast(message)
            
        }

        const closeModal = () => {
            $('#addCategoryModal').hide('modal')
            editMode.value = false
        }

        bus.on(SHOW_MODAL, data => {
            const categoryData = _.cloneDeep(data)
            const {id: categoryId, name, units, type, contains_sellables} = categoryData
            form.name = name;
            form.units = units; 
            form.type = type;
            form.contains_sellables = contains_sellables;
            id.value = categoryId;
            editMode.value = categoryData.hasOwnProperty('editMode') ? categoryData.editMode : false
            bus.off(SHOW_MODAL)

        })
        return {
            form,
            saveCategory,
            closeModal,
            editMode
        }
    }
}
</script>

<style lang="scss" scoped>

</style>