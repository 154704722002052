<template>
    <div class="item-add-section">
        <p class="heading">Items</p>
        <router-link to="/add-item"> <i class="fa fa-plus" aria-hidden="true"></i> </router-link>
    </div>
    <div class="items-container">
        <div class="items" v-if="items.length > 0">
            <Item v-for="item in items" :item="item" :key="item.id"/>                 
        </div>
    </div>
    <Refills />
</template>

<script>
import { computed, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Item from './Item.vue'
import Refills from '../../modals/Refills.vue'
export default {
    components: { Item, Refills},
    setup () {
        const store = useStore()
        const items = computed(() => store.getters['item/items'])

        const getItems = async () => {
            await store.dispatch('item/getItems')
        }

        getItems()
        return {
            items
        }
    }
}
</script>